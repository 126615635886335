@charset "UTF-8";
/* CSS Document */


@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=3a1cb204-e7bd-4874-a161-a2bfbc499423");

    /*@font-face{
        font-family:"Dreamer Script W00 Normal";
        src:url("/_fonts/011e1ee4-fc8a-4064-9d48-58651cc52e8f.woff2") format("woff2"),url("/_fonts/d92c5ea9-d99e-432c-a15b-a519f72ad857.woff") format("woff");
    }
    @font-face{
        font-family:"Absolute Beauty Serif W05 Thin";
        src:url("/_fonts/6560c654-cc1f-4d46-b789-1dcced503824.woff2") format("woff2"),url("/_fonts/4a283665-4a17-4c1b-acf4-da46f4bfcc59.woff") format("woff");
    }
    @font-face{
        font-family:"Absolute Beauty Serif W05 Rg";
        src:url("/_fonts/bebc20c8-774a-4e02-99cb-6b53e2dd837f.woff2") format("woff2"),url("/_fonts/5a8e07d5-c428-48a6-92ab-adcf31b8b03d.woff") format("woff");
    }
    @font-face{
        font-family:"Absolute Beauty Script W05 Lt";
        src:url("/_fonts/d61a8ecd-147d-4902-ac18-4f1110e831ec.woff2") format("woff2"),url("/_fonts/4ecf2bee-d469-4351-99c3-56c40478976b.woff") format("woff");
    }
    @font-face{
        font-family:"Absolute Beauty Script W05 Rg";
        src:url("/_fonts/da207973-a091-4086-ba36-19e97c64ddfd.woff2") format("woff2"),url("/_fonts/c3ca3a90-9289-47c4-a642-e274738c534a.woff") format("woff");
    }
    @font-face{
        font-family:"Absolute Beauty Script W05 Bol";
        src:url("/_fonts/ea780914-e731-49c3-9339-650c8b57ac3a.woff2") format("woff2"),url("/_fonts/8c63a884-be4e-4775-ae18-3ec7d0a5c0df.woff") format("woff");
    }
    @font-face{
        font-family:"Adagio W01 Regular";
        src:url("/_fonts/9e6faf7a-63c6-4cb8-96af-19f529bb3e05.woff2") format("woff2"),url("/_fonts/3043a5e5-a6ac-4478-81b6-32ebd2c1f950.woff") format("woff");
    }
    @font-face{
        font-family:"Ador W01 Light";
        src:url("/_fonts/99e52a91-cd92-4f83-b85a-83b01323d552.woff2") format("woff2"),url("/_fonts/1499358e-b909-45ec-be61-3b50c2a22a34.woff") format("woff");
    }
    @font-face{
        font-family:"Ador W01 Light-Italic";
        src:url("/_fonts/2a9ad7b3-ee98-44ad-b865-612249a22a9e.woff2") format("woff2"),url("/_fonts/60958ef0-90c3-45c2-be72-fb7f102a963a.woff") format("woff");
    }
    @font-face{
        font-family:"Ador W01 Regular";
        src:url("/_fonts/1067c1c8-add4-49d9-b63d-4be483871f77.woff2") format("woff2"),url("/_fonts/a7c5b2d3-d682-40fa-948a-2a9abd786e6a.woff") format("woff");
    }
    @font-face{
        font-family:"Ador W01 Italic";
        src:url("/_fonts/3c8c18c3-49c9-4d5a-a834-996c7f21c2a3.woff2") format("woff2"),url("/_fonts/c958fdc9-c395-4c69-8a40-be2ef45361ca.woff") format("woff");
    }
    @font-face{
        font-family:"Adore You W05 Regular";
        src:url("/_fonts/861ecea2-6e7d-475a-8673-472341a193c7.woff2") format("woff2"),url("/_fonts/a627d88a-c97f-4f25-bb0a-61e149cb85ac.woff") format("woff");
    }
    @font-face{
        font-family:"AdornS Pomander W00 Regular";
        src:url("/_fonts/ca11daa8-52c2-431a-9ced-c0d16d0f7649.woff2") format("woff2"),url("/_fonts/c43b47ce-1284-41a7-bb40-2f7fdcec5db4.woff") format("woff");
    }
    @font-face{
        font-family:"Ainslie W01 Norm Book1507059";
        src:url("/_fonts/e91e4c50-98cf-4175-a211-2dc977ddd596.woff2") format("woff2"),url("/_fonts/f9fd1963-d9f6-4584-a0a2-3e28a2281b3a.woff") format("woff");
    }
    @font-face{
        font-family:"Always W01 Regular";
        src:url("/_fonts/f1e76ef4-83dd-4c9f-a294-8971d156674d.woff2") format("woff2"),url("/_fonts/e58e4c95-3051-4182-84e6-f8f489a1e14b.woff") format("woff");
    }
    @font-face{
        font-family:"Ambar W01 Regular";
        src:url("/_fonts/2fa6bec6-a539-44e9-9a64-d07562d0b113.woff2") format("woff2"),url("/_fonts/2834059a-e3fe-4eee-940f-65a1408cfe85.woff") format("woff");
    }
    @font-face{
        font-family:"Ambiance BT W01 Regular";
        src:url("/_fonts/402c4372-5125-46c7-9923-7dd4ddb42328.woff2") format("woff2"),url("/_fonts/43294fec-4bc9-4c75-8ed5-56b0d50693ea.woff") format("woff");
    }
    @font-face{
        font-family:"Amster W00 Fina";
        src:url("/_fonts/6daa399c-8b33-4a85-8b29-c92c5dc49f42.woff2") format("woff2"),url("/_fonts/de7f6608-f2ca-488e-89f8-826f98e28fd4.woff") format("woff");
    }
    @font-face{
        font-family:"Andale-MonoW01-Regular";
        src:url("/_fonts/baecdc6e-fed3-4e54-b85b-27da18cede16.woff2") format("woff2"),url("/_fonts/b97837db-df23-42b4-834d-5c15d504e31c.woff") format("woff");
    }
    @font-face{
        font-family:"Anna Clara W00 Regular";
        src:url("/_fonts/d8c10fad-ada1-42a2-8fb8-27f68ba1187c.woff2") format("woff2"),url("/_fonts/b731626d-05e7-4c33-92bf-c15036f785ef.woff") format("woff");
    }
    @font-face{
        font-family:"CAL Aperto W01";
        src:url("/_fonts/4224ae2e-4427-4902-a063-1e36f60149e6.woff2") format("woff2"),url("/_fonts/50166461-07c9-4860-a49e-0b9a0a075504.woff") format("woff");
    }
    @font-face{
        font-family:"AracneUltraCondensedReg1437844";
        src:url("/_fonts/9e383a73-aff6-4e59-af6e-d1b110e8e85f.woff2") format("woff2"),url("/_fonts/83df24f3-2c1b-4d7c-a2d8-e640988fa929.woff") format("woff");
    }
    @font-face{
        font-family:"Archivio W00_500";
        src:url("/_fonts/0c143d7a-a7f5-409c-a95a-ded6a3a91fce.woff2") format("woff2"),url("/_fonts/2db41cc5-cefd-48d6-b4c1-f5010edd3354.woff") format("woff");
    }
    @font-face{
        font-family:"Archivio W00 Slab_500";
        src:url("/_fonts/af625c37-ad54-4909-bf75-ea551cdc3574.woff2") format("woff2"),url("/_fonts/029916d6-87f9-4deb-a21f-0019159da14d.woff") format("woff");
    }
    @font-face{
        font-family:"Asterism W00 Regular";
        src:url("/_fonts/c203617e-9b47-4a5f-a799-490b5077e1e3.woff2") format("woff2"),url("/_fonts/7c5121c3-8543-4436-9f9b-0e1c6304a104.woff") format("woff");
    }
    @font-face{
        font-family:"AvenirNextLTW01-UltraLi";
        src:url("/_fonts/2e3f5cb9-101f-46cf-a7b3-dfaa58261e03.woff2") format("woff2"),url("/_fonts/fa19948e-5e38-4909-b31e-41acd170d6f2.woff") format("woff");
    }
    @font-face{
        font-family:"Avenir Next W01 Thin";
        src:url("/_fonts/64779e28-a3b8-4364-949c-771372a0a327.woff2") format("woff2"),url("/_fonts/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format("woff");
    }
    @font-face{
        font-family:"AvenirNextW01-LightItal";
        src:url("/_fonts/62e28d52-436d-40b8-a5f0-00479f3f44ed.woff2") format("woff2"),url("/_fonts/5b8c6fdc-0ba4-4b65-87e4-d5ae51245ea5.woff") format("woff");
    }
    @font-face{
        font-family:"AvenirNextLTW01-Regular";
        src:url("/_fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("/_fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff");
    }
    @font-face{
        font-family:"Avenir Next LT W01 Demi";
        src:url("/_fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),url("/_fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff");
    }
    @font-face{
        font-family:"Bank Script W01";
        src:url("/_fonts/3c9ddbba-fe78-41e8-9635-686cadc9b3c4.woff2") format("woff2"),url("/_fonts/44746f1f-57d3-4802-abfb-a8b77800e3b0.woff") format("woff");
    }
    @font-face{
        font-family:"Bebas Neue W01 Regular";
        src:url("/_fonts/e0d6f852-5401-4bbf-9672-47a50c5c87c6.woff2") format("woff2"),url("/_fonts/7fedd582-6ae3-4850-be2f-4acae2e74fa5.woff") format("woff");
    }
    @font-face{
        font-family:"Bell MT W05 Regular";
        src:url("/_fonts/8ec54fa4-bc82-4534-b392-8f2e42985368.woff2") format("woff2"),url("/_fonts/e23bed69-9f54-4840-a9a9-b44086120965.woff") format("woff");
    }
    @font-face{
        font-family:"Bell MT W05 Italic";
        src:url("/_fonts/5c12814e-01f7-4149-a900-96623a90cf58.woff2") format("woff2"),url("/_fonts/a7bd1f29-fb48-45fc-b745-72eca0e14df6.woff") format("woff");
    }
    @font-face{
        font-family:"Bell MT W05 Bold";
        src:url("/_fonts/56292714-39fd-4979-95bd-ca516bda959e.woff2") format("woff2"),url("/_fonts/14a664ec-f1ae-48b2-a31e-374db5f3c147.woff") format("woff");
    }
    @font-face{
        font-family:"Bird and Thorn W05 Regular";
        src:url("/_fonts/11c6aeb4-7b81-495c-993c-3a5a5eae62ba.woff2") format("woff2"),url("/_fonts/ad7230bf-12b5-4f04-865c-128399e9d26c.woff") format("woff");
    }
    @font-face{
        font-family:"Blog Script W00 Regular";
        src:url("/_fonts/85c4764c-a16d-4e57-ac27-1a8a9f24a271.woff2") format("woff2"),url("/_fonts/d4222e93-d151-404f-a614-a33697dac925.woff") format("woff");
    }
    @font-face{
        font-family:"Bodebeck LT W01";
        src:url("/_fonts/777ceda3-8d5d-41ff-b997-0184e52aad8e.woff2") format("woff2"),url("/_fonts/2316f68a-51be-4b73-8b3e-9697578767a6.woff") format("woff");
    }
    @font-face{
        font-family:"Bodebeck LT W01 Italic";
        src:url("/_fonts/ff6916f5-ff2b-40d7-b7e2-5486b58f9fd6.woff2") format("woff2"),url("/_fonts/8b22a4c9-c12e-41e1-a317-57b85a40ef04.woff") format("woff");
    }
    @font-face{
        font-family:"Bodebeck LT W01 Bold";
        src:url("/_fonts/1b4ea389-105e-40f4-ac4a-0db4f4d893ab.woff2") format("woff2"),url("/_fonts/b77ae1f9-6cd2-47c1-b577-7e1dd7498dbe.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni MT W01 Book";
        src:url("/_fonts/73f99668-7882-4f92-ba4d-c3b149828f67.woff2") format("woff2"),url("/_fonts/d7f7fba3-4873-4084-b7f2-6f3fa4ee6019.woff") format("woff");
    }
    @font-face{
        font-family:"BodoniMTW01-BookItalic";
        src:url("/_fonts/a02f71e1-59b5-46db-a78d-84bbf0abef28.woff2") format("woff2"),url("/_fonts/fef20495-bab9-40eb-a873-7791998e1f4f.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni Sans W00 Regular";
        src:url("/_fonts/32759b45-5b2c-4062-8a08-0a62c9be6d70.woff2") format("woff2"),url("/_fonts/e5f8f401-28e7-4689-9fee-a3486da12974.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni Sans Display W05 Light";
        src:url("/_fonts/b0f9b3cf-49e7-4585-8f38-dc470cae2c44.woff2") format("woff2"),url("/_fonts/ab3159e7-8fcf-4855-a467-32ada7dbc92c.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni Sans Display W05 Lt It";
        src:url("/_fonts/020ea68c-9f5a-4d71-b5e8-e2787faace96.woff2") format("woff2"),url("/_fonts/8e0285ad-ea33-40ee-a01f-23bc4dc5ea61.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni Sans Display W05 Regula";
        src:url("/_fonts/fd1087bd-467c-4745-87b6-257a68cb5950.woff2") format("woff2"),url("/_fonts/6969a8fb-90c1-40ee-8e07-10fd58a00f33.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni Sans Display W05 Italic";
        src:url("/_fonts/c85a080e-d7aa-4b59-871f-4a52b32bf311.woff2") format("woff2"),url("/_fonts/6a7e8c70-d2e8-4217-88fc-bc025d50963b.woff") format("woff");
    }
    @font-face{
        font-family:"Bodoni W01 Poster691960";
        src:url("/_fonts/3b70fee6-3ed6-4f8b-b59c-bf7274c4ff8c.woff2") format("woff2"),url("/_fonts/4d1b9848-7ebd-472c-9d31-4af0aa7faaea.woff") format("woff");
    }
    @font-face{
        font-family:"Boho Script W01 Regular";
        src:url("/_fonts/d18654c7-b80f-4229-a98f-a5dedd6a9eb2.woff2") format("woff2"),url("/_fonts/8c11fc94-dad5-4666-a6c2-a36958f9657a.woff") format("woff");
    }
    @font-face{
        font-family:"Born Ready W05 Regular";
        src:url("/_fonts/3f7ccbcc-9890-48b7-9359-92a621a6d41d.woff2") format("woff2"),url("/_fonts/27e4bc49-8b80-423b-a9e1-a7e92d104f96.woff") format("woff");
    }
    @font-face{
        font-family:"Botanica Script W00 Regular";
        src:url("/_fonts/11524b8e-4d5a-434e-a60c-0c561069e8e7.woff2") format("woff2"),url("/_fonts/0f836b3f-3faf-4f74-b3cd-a6c864217b03.woff") format("woff");
    }
    @font-face{
        font-family:"Botanica Script W00 DryBrush";
        src:url("/_fonts/672f976e-f7c6-4002-80ed-2f6937e8f81e.woff2") format("woff2"),url("/_fonts/ce13e225-88ef-410a-ba58-e32885600b4d.woff") format("woff");
    }
    @font-face{
        font-family:"Bow tie W05 Regular";
        src:url("/_fonts/f4e58080-f176-4561-b5f6-edd7a9e5089b.woff2") format("woff2"),url("/_fonts/76a68bcb-c0c7-4a31-a331-dde53563f147.woff") format("woff");
    }
    @font-face{
        font-family:"Bradstone-Parker Script W00 Rg";
        src:url("/_fonts/6a1cdb18-e1a4-41a2-a404-3f4708eb673f.woff2") format("woff2"),url("/_fonts/791066d7-f419-443a-9021-845951c277bb.woff") format("woff");
    }
    @font-face{
        font-family:"Braggadocio W01";
        src:url("/_fonts/6622a378-f851-43b7-bfe6-d9703b750483.woff2") format("woff2"),url("/_fonts/518e4577-eecc-4ecd-adb4-2ee21df35b20.woff") format("woff");
    }
    @font-face{
        font-family:"Brandon Grot W01 Light";
        src:url("/_fonts/69b40392-453a-438a-a121-a49e5fbc9213.woff2") format("woff2"),url("/_fonts/9362bca5-b362-4543-a051-2129e2def911.woff") format("woff");
    }
    @font-face{
        font-family:"BrandonGrotW01-Regular";
        src:url("/_fonts/1e5fc8fa-a98c-4613-a08e-c6c975759a80.woff2") format("woff2"),url("/_fonts/6b45f9ca-22b4-48ca-962f-6ff1fa7fc196.woff") format("woff");
    }
    @font-face{
        font-family:"Brandon Grot W01 Bold";
        src:url("/_fonts/df1cd944-a6a3-4d30-8fd3-391c2e9531f1.woff2") format("woff2"),url("/_fonts/4f2d0548-c7dc-459b-a2e0-e86656a10ded.woff") format("woff");
    }
    @font-face{
        font-family:"Brandon Grotesque W01 Light It";
        src:url("/_fonts/3888fe55-0bb7-468f-a3e6-aee6678b7714.woff2") format("woff2"),url("/_fonts/42e0d641-6b7d-4ef7-8eaa-05693d179d8a.woff") format("woff");
    }
    @font-face{
        font-family:"Brandon Grotesque W01 Rg It";
        src:url("/_fonts/40ae7f1f-757a-4df4-90bd-6c1ea77a6cc0.woff2") format("woff2"),url("/_fonts/0bd54cb1-cfb7-4292-9e99-861508a6df66.woff") format("woff");
    }
    @font-face{
        font-family:"Brev Script W01 Regular";
        src:url("/_fonts/f93b5f9b-f69a-485c-8b5a-ab4e2e463cef.woff2") format("woff2"),url("/_fonts/1fa2d7f2-2c0a-4760-a9db-4228e70124ba.woff") format("woff");
    }
    @font-face{
        font-family:"BrushTipTerrenceW00-Reg";
        src:url("/_fonts/73802510-4ec8-4e41-9523-9b6a55241724.woff2") format("woff2"),url("/_fonts/3b45ce82-da26-4d9b-aa77-2ce0c4626e18.woff") format("woff");
    }
    @font-face{
        font-family:"BrushTip Texe W00 Reg";
        src:url("/_fonts/980bd8a5-c319-4979-a27f-d6770ee41449.woff2") format("woff2"),url("/_fonts/a1483cf0-aa5b-4871-9a71-3a73a8619e05.woff") format("woff");
    }
    @font-face{
        font-family:"KG Camden Market Script W00 Rg";
        src:url("/_fonts/d23ef872-5ef1-4ead-98b2-df5bfbb1680d.woff2") format("woff2"),url("/_fonts/526de207-594e-46ab-ac1c-9345a5f7d6b6.woff") format("woff");
    }
    @font-face{
        font-family:"Camera W01 Regular";
        src:url("/_fonts/0fbc49d4-eef6-438f-ba04-fb304466c5b5.woff2") format("woff2"),url("/_fonts/7132d40d-0b8c-44fc-8214-85b8846688c6.woff") format("woff");
    }
    @font-face{
        font-family:"Camila W05 Light";
        src:url("/_fonts/b6b43f86-56ce-4c03-911a-fe144e3b0609.woff2") format("woff2"),url("/_fonts/8a558a9e-534d-4073-a7f1-5180642008e6.woff") format("woff");
    }
    @font-face{
        font-family:"Camila W05 Light It";
        src:url("/_fonts/6e3d05a8-59a2-4199-b66a-bca03022fda6.woff2") format("woff2"),url("/_fonts/d226e141-8b21-4514-848d-bcc1d7852fe7.woff") format("woff");
    }
    @font-face{
        font-family:"Camila W05 Medium";
        src:url("/_fonts/97f71af9-da59-482d-9a81-11baaa199880.woff2") format("woff2"),url("/_fonts/a38999e4-02ab-46a7-9c17-120a145c9d3c.woff") format("woff");
    }
    @font-face{
        font-family:"Camila W05 Medium It";
        src:url("/_fonts/47f07849-f5cc-455e-9de6-fea0158f4938.woff2") format("woff2"),url("/_fonts/761ce83a-c4a8-4e69-9603-cd2d8ee26169.woff") format("woff");
    }
    @font-face{
        font-family:"Cantoria W01";
        src:url("/_fonts/f71cf996-20d1-4099-b824-7dedb412f976.woff2") format("woff2"),url("/_fonts/8c448638-2d30-48da-84ce-054bd19bcb30.woff") format("woff");
    }
    @font-face{
        font-family:"Carl Beck W00 Regular";
        src:url("/_fonts/f2e11f03-b093-48fa-9489-2cd3a3d44dc3.woff2") format("woff2"),url("/_fonts/9dd2d561-6753-4feb-a886-5d7ad271de55.woff") format("woff");
    }
    @font-face{
        font-family:"Carolyna W00 Regular";
        src:url("/_fonts/b61b4bdf-99c1-47e3-bbbe-38b151e96303.woff2") format("woff2"),url("/_fonts/688b6449-b537-49e2-9f98-872e1d3aa39e.woff") format("woff");
    }
    @font-face{
        font-family:"Carolyna W00 Cute";
        src:url("/_fonts/aac192ef-0b87-4eea-9e97-2bd5bbe77cb8.woff2") format("woff2"),url("/_fonts/92f444d4-df5e-49a1-9d3d-09e5fd6592b7.woff") format("woff");
    }
    @font-face{
        font-family:"Catalina Anacapa Sans W00 Bold";
        src:url("/_fonts/d8431b66-94c6-460f-8174-7a10dba43a2c.woff2") format("woff2"),url("/_fonts/13c2f49a-179a-4f67-92a0-a88119a337bd.woff") format("woff");
    }
    @font-face{
        font-family:"Century Gothic W01";
        src:url("/_fonts/700cfd4c-3384-4654-abe1-aa1a6e8058e4.woff2") format("woff2"),url("/_fonts/9908cdad-7524-4206-819e-4f345a666324.woff") format("woff");
    }
    @font-face{
        font-family:"Century Gothic W01 Bold";
        src:url("/_fonts/bc0668e2-e5c8-45ce-954d-3b998c6b6803.woff2") format("woff2"),url("/_fonts/2ef5ecda-1772-45e2-843f-a13f5d76ed3a.woff") format("woff");
    }
    @font-face{
        font-family:"Checkmark W05 Regular";
        src:url("/_fonts/15f311e1-62d0-4b39-a71b-0bd4a5162602.woff2") format("woff2"),url("/_fonts/c3174c1b-f734-4705-b1aa-e790c3d7ab07.woff") format("woff");
    }
    @font-face{
        font-family:"Classic Roman W01";
        src:url("/_fonts/42be7a82-b97c-487d-81cb-334039fcce60.woff2") format("woff2"),url("/_fonts/ab6d2acf-c9e5-40d7-9d3c-a4dc3cbb5183.woff") format("woff");
    }
    @font-face{
        font-family:"Cocobella W04 Regular";
        src:url("/_fonts/03d658d2-84da-4f8f-a76c-8df0d03e50d6.woff2") format("woff2"),url("/_fonts/821ba14c-ec84-455b-9767-4995f95cd82c.woff") format("woff");
    }
    @font-face{
        font-family:"Coco Gothic Alternate W01 UltL";
        src:url("/_fonts/8d4ace84-bfc4-4a5f-81be-6f0453172e89.woff2") format("woff2"),url("/_fonts/2b51ef88-2cb5-4529-9393-9909ad60192f.woff") format("woff");
    }
    @font-face{
        font-family:"Compendium W00 Regular";
        src:url("/_fonts/e42ae012-ae43-4878-9ef6-4b190c6709be.woff2") format("woff2"),url("/_fonts/30f5addf-0db0-43f1-ac1b-87632166804a.woff") format("woff");
    }
    @font-face{
        font-family:"Conspired Lovers W05 Regular";
        src:url("/_fonts/8bf7833a-1880-4b3d-bcfb-f20c079a9a20.woff2") format("woff2"),url("/_fonts/25949130-edc7-46c3-aaa6-7691ffa2f69c.woff") format("woff");
    }
    @font-face{
        font-family:"Constancia Script W05 Bold";
        src:url("/_fonts/2ccbfd2c-aff3-4c42-bd1d-2cadb1a46a8a.woff2") format("woff2"),url("/_fonts/d58f0120-d7e9-41ad-90ec-6b6656ac731c.woff") format("woff");
    }
    @font-face{
        font-family:"Coral Blush Serif W00 Regular";
        src:url("/_fonts/765e7c70-c9e3-4f50-be61-d8d5cb0ad9f7.woff2") format("woff2"),url("/_fonts/f14b2323-909a-4cf9-b718-ac8690a96ae6.woff") format("woff");
    }
    @font-face{
        font-family:"Coral Blush Script W00 Regular";
        src:url("/_fonts/9aab1ee1-c2f3-464f-b2cd-43bd3b1cca49.woff2") format("woff2"),url("/_fonts/576b16f2-a333-4421-8ed2-a12d022353b3.woff") format("woff");
    }
    @font-face{
        font-family:"Crystal Sky Alt W05 Regular";
        src:url("/_fonts/9c1b7899-0f0a-4a12-9b92-0e8e62e9d22c.woff2") format("woff2"),url("/_fonts/8af0343b-3493-4da0-a72a-f9ddc9cbd187.woff") format("woff");
    }
    @font-face{
        font-family:"DearJoe_4 W00 Reg Pro";
        src:url("/_fonts/4bf5ea23-fed5-499c-85b1-f7027691d819.woff2") format("woff2"),url("/_fonts/90c51667-78d4-4680-80d6-b7f845a935c3.woff") format("woff");
    }
    @font-face{
        font-family:"dear_JoeHannes_CG W00 Regular";
        src:url("/_fonts/e5a8592c-157e-4803-8c0c-92052d5c1106.woff2") format("woff2"),url("/_fonts/923e97e3-1243-4df6-a98d-3110e41c5803.woff") format("woff");
    }
    @font-face{
        font-family:"Didot W01 Roman";
        src:url("/_fonts/ca8dded6-b7b2-4d3c-b45e-a32de5023d4d.woff2") format("woff2"),url("/_fonts/8cf278e2-5b07-4c21-97da-4762c9ea76c1.woff") format("woff");
    }
    @font-face{
        font-family:"Didot W01 Italic";
        src:url("/_fonts/abe3d3a9-c990-459f-9407-54ac96cd2f00.woff2") format("woff2"),url("/_fonts/09a4b57b-7400-4d30-b4ba-d6e303c57868.woff") format("woff");
    }
    @font-face{
        font-family:"Didot W01 Bold";
        src:url("/_fonts/5cc88c79-07f5-4dc3-808c-0a24f606acff.woff2") format("woff2"),url("/_fonts/8c847ae1-0c02-4e9a-9203-c294e4a23fe1.woff") format("woff");
    }
    @font-face{
        font-family:"DIN Next W01 Regular";
        src:url("/_fonts/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"),url("/_fonts/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff");
    }
    @font-face{
        font-family:"EdwardianScriptITCW01";
        src:url("/_fonts/d99f0ff7-9f4b-4d62-9283-7de76d63580e.woff2") format("woff2"),url("/_fonts/e92a529b-97f5-440f-b8a4-a2ad31c621c4.woff") format("woff");
    }
    @font-face{
        font-family:"Elegy W01";
        src:url("/_fonts/e76246b5-6787-41e3-8c65-58804db2e206.woff2") format("woff2"),url("/_fonts/c48bb56e-8115-4e0f-ad65-9b3e0c17962d.woff") format("woff");
    }
    @font-face{
        font-family:"Encina Script_2 W01 Regular";
        src:url("/_fonts/4f98ed88-5002-45d6-a406-fec1d5cba8bb.woff2") format("woff2"),url("/_fonts/df642e9c-bd7a-4565-bbf7-b98f67cbae85.woff") format("woff");
    }
    @font-face{
        font-family:"Engravers W01";
        src:url("/_fonts/4c551d3b-0d6e-47c1-8f0b-546cd0a82965.woff2") format("woff2"),url("/_fonts/0246fd62-0167-4b8a-a84d-dcfb0f3b0de4.woff") format("woff");
    }
    @font-face{
        font-family:"EngraversGothicBTW01-Re";
        src:url("/_fonts/9edbccdb-d742-4854-be92-116cb48455c2.woff2") format("woff2"),url("/_fonts/46d6733f-4688-4a24-b4b9-c606b06c6620.woff") format("woff");
    }
    @font-face{
        font-family:"ErasedTypewriter2 W00 Regular";
        src:url("/_fonts/07bdf048-7f8a-4117-8066-e1eddba726e1.woff2") format("woff2"),url("/_fonts/1930ef50-0c0d-4219-8754-263ea5cba097.woff") format("woff");
    }
    @font-face{
        font-family:"Fairbank W01 Regular";
        src:url("/_fonts/2ab279f9-5b10-4b68-8492-799afe48b892.woff2") format("woff2"),url("/_fonts/60a2d8d0-cdac-4ba5-92b5-e73ad749c050.woff") format("woff");
    }
    @font-face{
        font-family:"FG Elias W00 Regular";
        src:url("/_fonts/b63bfe80-585a-4e5a-9125-a2959546967a.woff2") format("woff2"),url("/_fonts/d217632c-f95d-42b0-83eb-8383c606c4b7.woff") format("woff");
    }
    @font-face{
        font-family:"Florentine ScriptII W01";
        src:url("/_fonts/4d331c0e-8369-4d23-9a4a-8661796c9f73.woff2") format("woff2"),url("/_fonts/c362dc1e-7961-4a70-bfee-d8956e246109.woff") format("woff");
    }
    @font-face{
        font-family:"Futura LT W01 Light";
        src:url("/_fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"),url("/_fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff");
    }
    @font-face{
        font-family:"Futura LT W01 Book";
        src:url("/_fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("/_fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff");
    }
    @font-face{
        font-family:"Futura LT W01 Bold";
        src:url("/_fonts/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2") format("woff2"),url("/_fonts/184895ac-545b-450b-9d3a-c0a0a7620977.woff") format("woff");
    }
    @font-face{
        font-family:"Futura LT W01 Light Condensed";
        src:url("/_fonts/73956fa0-5cd8-4815-bacd-229aaac08ea2.woff2") format("woff2"),url("/_fonts/8eb4bbf6-6c6e-46a0-a9cc-dab8c61069c6.woff") format("woff");
    }
    @font-face{
        font-family:"GaramondClassico W00 SC";
        src:url("/_fonts/e8bd12aa-ef4a-4229-955c-1f1fb08eae89.woff2") format("woff2"),url("/_fonts/3bb7dd0a-ad62-4105-949e-14afa8643e85.woff") format("woff");
    }
    @font-face{
        font-family:"AdobeGaramondW01-Regula";
        src:url("/_fonts/d5ca2f36-723d-4882-8658-9746fb0eea72.woff2") format("woff2"),url("/_fonts/859018fa-3c03-484b-8774-97bd6548d48d.woff") format("woff");
    }
    @font-face{
        font-family:"Adobe Garamond W01 It";
        src:url("/_fonts/68e92036-cf88-44c3-8061-e7d30fc9d1c4.woff2") format("woff2"),url("/_fonts/bb497139-4ed5-41d1-905c-46d29a1b30f8.woff") format("woff");
    }
    @font-face{
        font-family:"GFY Thornesmith W00 Reg";
        src:url("/_fonts/6d4c9203-cad1-42c6-b750-476b4273530e.woff2") format("woff2"),url("/_fonts/813aa8ea-20a1-4697-9f17-7774007a57e8.woff") format("woff");
    }
    @font-face{
        font-family:"Gill Sans Nova W01 Book";
        src:url("/_fonts/43b67b68-8704-4bc0-9c41-620c664cb9dd.woff2") format("woff2"),url("/_fonts/37a7a423-c7fe-48f9-8013-63d2cc236c7a.woff") format("woff");
    }
    @font-face{
        font-family:"Gill Sans Nova W01 SemiBold";
        src:url("/_fonts/5fb9e481-085f-4ad5-8574-ffabb4dbe7a9.woff2") format("woff2"),url("/_fonts/cc1cc8a6-92c2-4d62-973e-e4a20c3df0bf.woff") format("woff");
    }
    @font-face{
        font-family:"Goudy Old Style MT W01";
        src:url("/_fonts/9f1d6b55-5aa3-4e50-aea5-9e9b9840e81f.woff2") format("woff2"),url("/_fonts/26d18886-bdda-49b3-b3eb-3805be0652bb.woff") format("woff");
    }
    @font-face{
        font-family:"GoudyOldStyleMTW01-Ital";
        src:url("/_fonts/33effc45-0841-4abe-ac27-30d5aaf8695c.woff2") format("woff2"),url("/_fonts/9f1e66d0-f6ab-4d39-95ef-8d38218c6358.woff") format("woff");
    }
    @font-face{
        font-family:"Gravura W01";
        src:url("/_fonts/32a53d6b-de2f-40f8-953d-2869a162f919.woff2") format("woff2"),url("/_fonts/fcadc906-4fdf-4347-9e88-ce7985ad7fbe.woff") format("woff");
    }
    @font-face{
        font-family:"Guadalupe W03 Regular";
        src:url("/_fonts/2ecd2464-4bd3-48da-8629-cc7791328bc0.woff2") format("woff2"),url("/_fonts/86a76832-101a-44ee-b8ea-862ae195157c.woff") format("woff");
    }
    @font-face{
        font-family:"Guadalupe W03 It";
        src:url("/_fonts/fca4894f-f178-44cd-a3a8-8c5b374a7d96.woff2") format("woff2"),url("/_fonts/905cad08-da67-4897-a6e8-8fb7bd2bba3e.woff") format("woff");
    }
    @font-face{
        font-family:"Guess W05 Regular";
        src:url("/_fonts/ebebb134-2f3b-46e7-89dd-3b44ef492722.woff2") format("woff2"),url("/_fonts/7f5f2166-a93d-4e54-806e-01b8a40ed52d.woff") format("woff");
    }
    @font-face{
        font-family:"HavanaSunset Script W00 Regula";
        src:url("/_fonts/c5231264-986d-4b4a-8a00-986f3542f181.woff2") format("woff2"),url("/_fonts/9d057314-4e58-47a1-9ba7-50421e02ac50.woff") format("woff");
    }
    @font-face{
        font-family:"Hello Beautiful W05 Regular";
        src:url("/_fonts/ecd4b509-15df-467b-abc5-ffe1e281027f.woff2") format("woff2"),url("/_fonts/4a3122b2-c9f2-4355-9d08-7e326dd01a00.woff") format("woff");
    }
    @font-face{
        font-family:"Hello Beautiful Marker W05 Rg";
        src:url("/_fonts/47a94f09-5ce8-44a2-bf5e-0b2a278742a1.woff2") format("woff2"),url("/_fonts/4b5c7de7-5652-4694-ae04-30ef55e35d9c.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica LT W01 Roman";
        src:url("/_fonts/89294146-207c-4803-91fe-338f0d0094bd.woff2") format("woff2"),url("/_fonts/57055fe4-c2d3-4287-bac2-eece43fdcbd3.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica LT W01 Bold";
        src:url("/_fonts/7ce0a2f5-eb00-46aa-919c-5b3f3667646c.woff2") format("woff2"),url("/_fonts/cad22c74-45b4-4c49-9e6a-0cd3768a7bc7.woff") format("woff");
    }
    @font-face{
        font-family:"Heroe W05 Regular5221551";
        src:url("/_fonts/ae3d87bb-0399-48f0-8489-0a2f1d79be13.woff2") format("woff2"),url("/_fonts/cf247502-e7f8-4af8-961c-a8e00758bf2c.woff") format("woff");
    }
    @font-face{
        font-family:"Houston Pen W01 Regular";
        src:url("/_fonts/fba513f5-594a-4639-81f3-49def4d2eae4.woff2") format("woff2"),url("/_fonts/cfa1aa66-f6ae-4e15-895e-b8cc4d561a89.woff") format("woff");
    }
    @font-face{
        font-family:"Icing W00 Regular";
        src:url("/_fonts/6408923b-be4b-4814-8d74-47552e72a9ee.woff2") format("woff2"),url("/_fonts/b42e43e2-9c02-4822-a3da-7ffc41a0c713.woff") format("woff");
    }
    @font-face{
        font-family:"Jerrywi W00 Regular";
        src:url("/_fonts/df32c54c-644e-4ea9-81b1-4573e53dff78.woff2") format("woff2"),url("/_fonts/23da3afe-58a4-44e4-8d49-7d55bbe1ebb0.woff") format("woff");
    }
    @font-face{
        font-family:"JRS W00 Regular";
        src:url("/_fonts/f26bc9e8-d808-45a4-b2a0-546028e5727d.woff2") format("woff2"),url("/_fonts/05913f4e-15d0-4a2e-835d-819bf6a6216b.woff") format("woff");
    }
    @font-face{
        font-family:"Keepsake W00 Regular";
        src:url("/_fonts/7ebe6783-f7f5-4c03-afb0-49cf06006d48.woff2") format("woff2"),url("/_fonts/a44f3f08-6590-45d5-81ae-cf51ddf135be.woff") format("woff");
    }
    @font-face{
        font-family:"KunstlerschreibschDOTMe1432698";
        src:url("/_fonts/855d601e-4937-4d0c-bc7e-b5b9413653ab.woff2") format("woff2"),url("/_fonts/3eab40e9-eb24-42f4-9437-6d59401d3ec0.woff") format("woff");
    }
    @font-face{
        font-family:"La Luxes Serif W00 Regular";
        src:url("/_fonts/abe5b497-6ad4-498a-a2b0-2a4f4b451f54.woff2") format("woff2"),url("/_fonts/8ab02410-e26f-4eee-b4be-3b88e5fdfa3c.woff") format("woff");
    }
    @font-face{
        font-family:"La Luxes Script W00 Regular";
        src:url("/_fonts/273e944a-f7dd-49c2-91c6-cf0554e1c1cb.woff2") format("woff2"),url("/_fonts/45f50335-adab-4f2c-8212-53adc3ce00e9.woff") format("woff");
    }
    @font-face{
        font-family:"La Veronique One W05 Regular";
        src:url("/_fonts/9c2435dd-624e-410a-9114-21479d007103.woff2") format("woff2"),url("/_fonts/980245c8-ed56-48d8-965e-c4c97002956a.woff") format("woff");
    }
    @font-face{
        font-family:"La Veronique Sans W05 Regular";
        src:url("/_fonts/8e6d2fb3-98b1-4a80-aacb-7c51a46e1ca3.woff2") format("woff2"),url("/_fonts/3970cb47-63c9-41c7-b883-5c750170f044.woff") format("woff");
    }
    @font-face{
        font-family:"Les Tulipes W01 Regular";
        src:url("/_fonts/af7ca4f6-ca02-40e1-8aec-e68ff08f9dcf.woff2") format("woff2"),url("/_fonts/54c5cf1b-2f53-4259-9957-50423316cd72.woff") format("woff");
    }
    @font-face{
        font-family:"LetterGothicW01-Regular";
        src:url("/_fonts/2daa5720-fcad-410d-8239-fc39de44bf16.woff2") format("woff2"),url("/_fonts/bed22532-e849-4769-b08c-248a505fd11d.woff") format("woff");
    }
    @font-face{
        font-family:"NewLetterGothicW01-Regu";
        src:url("/_fonts/5eed3302-834c-4ba2-beec-a1bc1c65ae4b.woff2") format("woff2"),url("/_fonts/d992180c-bfbf-4a9b-b784-29ab0f682082.woff") format("woff");
    }
    @font-face{
        font-family:"Libelle LT W01";
        src:url("/_fonts/4abfa574-fe25-43e2-adb5-04e717b01ab5.woff2") format("woff2"),url("/_fonts/42d79ac6-de92-4371-acb3-8e75d2eb2308.woff") format("woff");
    }
    @font-face{
        font-family:"Madina Script W00 Regular";
        src:url("/_fonts/1350f4be-7d64-45fc-8603-dcda4c6026fd.woff2") format("woff2"),url("/_fonts/18e7241d-103a-4c89-b437-aee3c76ff2b6.woff") format("woff");
    }
    @font-face{
        font-family:"Malik W01 Extralight";
        src:url("/_fonts/87614aba-242e-4fca-bcd8-258f39c81452.woff2") format("woff2"),url("/_fonts/63eacaea-55a4-47c6-85d9-c27185f6b4c7.woff") format("woff");
    }
    @font-face{
        font-family:"Malik W01 Light";
        src:url("/_fonts/38b1ec9d-bfb5-417e-88ce-88851245d7ba.woff2") format("woff2"),url("/_fonts/6bee5b2c-25f7-43fa-9ac6-07081d03b9b1.woff") format("woff");
    }
    @font-face{
        font-family:"Malik W01 Light Italic";
        src:url("/_fonts/7cb4acc4-e938-4824-84fe-c68fe97e3f21.woff2") format("woff2"),url("/_fonts/a4c1ee39-7b76-41f5-a6fa-7798a09c65e0.woff") format("woff");
    }
    @font-face{
        font-family:"Meritage W00 Regular1424716";
        src:url("/_fonts/02ff3867-3a1b-4787-b83c-f4b955abb10b.woff2") format("woff2"),url("/_fonts/2564964a-82dd-4490-8b7c-1fc7dcdeb61e.woff") format("woff");
    }
    @font-face{
        font-family:"Mina W00 Chic";
        src:url("/_fonts/16688a0c-2b79-4128-bf35-a87858b09837.woff2") format("woff2"),url("/_fonts/c6395ab5-943d-4148-9dd6-a023f63abc02.woff") format("woff");
    }
    @font-face{
        font-family:"Minion W01 Regular";
        src:url("/_fonts/18597b82-9a06-46a3-b68b-c250c1105515.woff2") format("woff2"),url("/_fonts/62100580-a2d1-4d98-bb14-d4c4c3f1a5ba.woff") format("woff");
    }
    @font-face{
        font-family:"Minion W01 It";
        src:url("/_fonts/3a415f59-f235-4fac-a05d-e270104dd8e6.woff2") format("woff2"),url("/_fonts/e364b06d-aac3-4a54-84ff-4b3a2860debb.woff") format("woff");
    }
    @font-face{
        font-family:"Misguided Three W00 Regular";
        src:url("/_fonts/c5bcc9b6-94f5-4340-80e1-c05976aa0812.woff2") format("woff2"),url("/_fonts/20e61f38-a668-479a-8d9b-2b7920e5541b.woff") format("woff");
    }
    @font-face{
        font-family:"MrsSaint-DelafieldW01-R";
        src:url("/_fonts/1352bef7-34fb-4f57-a96c-23e7f615bfe3.woff2") format("woff2"),url("/_fonts/e35d65f8-9ea6-44d1-930d-44b9ef7a47f5.woff") format("woff");
    }
    @font-face{
        font-family:"Mulberry Script W01 Reg1561387";
        src:url("/_fonts/316d1db8-fd46-4e22-9e5b-af4bd2a5853c.woff2") format("woff2"),url("/_fonts/37e5464e-7fa1-4253-921b-43544ece064d.woff") format("woff");
    }
    @font-face{
        font-family:"Museo W01_100";
        src:url("/_fonts/71e645d2-276d-4568-b9e4-e215b8e5b24f.woff2") format("woff2"),url("/_fonts/65bf816d-089f-4500-aad4-72a4f160b487.woff") format("woff");
    }
    @font-face{
        font-family:"Museo W01_300";
        src:url("/_fonts/d9dabe05-624c-4f28-8eee-b3b6f1841abf.woff2") format("woff2"),url("/_fonts/827f2f08-a8d3-40b2-9795-927b59905269.woff") format("woff");
    }
    @font-face{
        font-family:"Museo W01_500";
        src:url("/_fonts/52be0694-00c1-4daa-8782-419021c48e95.woff2") format("woff2"),url("/_fonts/dee84039-06ae-4a61-89c4-709e594804a3.woff") format("woff");
    }
    @font-face{
        font-family:"Museo Sans W01_100";
        src:url("/_fonts/fd9d5b5d-71c1-4db0-bf20-cd7e3ce4d069.woff2") format("woff2"),url("/_fonts/e976d680-12b6-411e-9f79-0ef9287f484d.woff") format("woff");
    }
    @font-face{
        font-family:"Museo Sans W01_300";
        src:url("/_fonts/1d3191cd-ae8c-45e2-bb04-11e96d8fa974.woff2") format("woff2"),url("/_fonts/b252d464-65cb-4950-88f7-ac0a1bf79b75.woff") format("woff");
    }
    @font-face{
        font-family:"Museo Sans W01_700";
        src:url("/_fonts/e2d37f34-6bca-473c-b64d-93811f4dc046.woff2") format("woff2"),url("/_fonts/eadbb595-a394-48fc-834f-8bf5da15cddf.woff") format("woff");
    }
    @font-face{
        font-family:"MuseoSansW01-Rounded300";
        src:url("/_fonts/66c80723-12ac-4a96-8303-d0eee61c32b1.woff2") format("woff2"),url("/_fonts/36d5ed39-d8cd-4558-a597-0f47565cdd0e.woff") format("woff");
    }
    @font-face{
        font-family:"Naive Sans W05 Light";
        src:url("/_fonts/e7f630a8-6bb5-4a1e-bb95-c215c46d0224.woff2") format("woff2"),url("/_fonts/efaf1096-77c1-418a-8492-10655771955f.woff") format("woff");
    }
    @font-face{
        font-family:"Naive Sans W05 Medium";
        src:url("/_fonts/3ef0ddd6-fc73-4f33-9ec0-6a49a2f6db30.woff2") format("woff2"),url("/_fonts/c2657420-2d19-4ab9-83cc-ae0461b14daf.woff") format("woff");
    }
    @font-face{
        font-family:"Naive Sans W05 Bold";
        src:url("/_fonts/85f30a44-1fdb-40ce-b89f-84f0f32d99f7.woff2") format("woff2"),url("/_fonts/33028274-1beb-49fa-bb42-3f6c67c809a0.woff") format("woff");
    }
    @font-face{
        font-family:"NeuzeitOfficeW01-Regula";
        src:url("/_fonts/ade0437c-075f-4d7c-9c40-7380d9683682.woff2") format("woff2"),url("/_fonts/f5e09b25-479c-4a4c-b681-5a6d440e5e38.woff") format("woff");
    }
    @font-face{
        font-family:"Normande W01 Roman";
        src:url("/_fonts/69bb92c2-4b67-4d10-90f6-17b37bf68aae.woff2") format("woff2"),url("/_fonts/bf17e5ab-06a0-435c-8dcd-bdb044d730d6.woff") format("woff");
    }
    @font-face{
        font-family:"TT Norms W01 Light";
        src:url("/_fonts/5755afca-1185-4cf9-bdff-c1a7a0b90a41.woff2") format("woff2"),url("/_fonts/35676be7-e596-4680-b97c-d0684419df85.woff") format("woff");
    }
    @font-face{
        font-family:"TT Norms W01 Regular";
        src:url("/_fonts/35406d8e-39ba-4b17-a795-746fd04b670d.woff2") format("woff2"),url("/_fonts/0ee99cb6-2e0b-4de2-83c6-8f1f8e107bf1.woff") format("woff");
    }
    @font-face{
        font-family:"TT Norms W01 Medium";
        src:url("/_fonts/e82f6cee-9013-4a8a-88b7-f588b977558a.woff2") format("woff2"),url("/_fonts/dd46c47a-49a0-4615-998d-2943c528e5d0.woff") format("woff");
    }
    @font-face{
        font-family:"Northwell Alt W00 Regular";
        src:url("/_fonts/32ba5042-df3b-4046-9388-054db6e3fd4d.woff2") format("woff2"),url("/_fonts/0d66a345-807d-4f4a-a22a-770ae3bea546.woff") format("woff");
    }
    @font-face{
        font-family:"Notec LT W01";
        src:url("/_fonts/d03505ed-fadd-4dfc-abfe-2f49daed4e47.woff2") format("woff2"),url("/_fonts/b0a49671-810c-4e56-bcb6-5ef6856c8ab0.woff") format("woff");
    }
    @font-face{
        font-family:"Orator W01 Medium";
        src:url("/_fonts/e4977404-64bf-4e3d-be03-5e314e0866a4.woff2") format("woff2"),url("/_fonts/ba28109f-1ade-45f5-bd09-a1ac3aec73f6.woff") format("woff");
    }
    @font-face{
        font-family:"PalaceScriptW01-Regular";
        src:url("/_fonts/bff32511-2599-4470-948d-2499e0278ccd.woff2") format("woff2"),url("/_fonts/ccd3f40f-c6da-40c6-a516-a1961beceb2d.woff") format("woff");
    }
    @font-face{
        font-family:"Parfumerie Script W00 Regular";
        src:url("/_fonts/81ace534-5ebf-433a-a605-6ce4a046b01d.woff2") format("woff2"),url("/_fonts/b2533eb7-8ef8-41b3-ac99-8ff97d1e7aab.woff") format("woff");
    }
    @font-face{
        font-family:"Peignot W01 Demi";
        src:url("/_fonts/6b34cbf9-b503-47d6-8dff-8cac8fd67f98.woff2") format("woff2"),url("/_fonts/c93c9e12-6a34-45ab-9214-016a038c48e9.woff") format("woff");
    }
    @font-face{
        font-family:"PF Champion Script W01 Regular";
        src:url("/_fonts/b64d3df1-02a4-4329-90cc-a5de423e0eb7.woff2") format("woff2"),url("/_fonts/861891cf-f553-43cc-81dc-3880e2c539c6.woff") format("woff");
    }
    @font-face{
        font-family:"Pica_10 Pitch W01 Roman";
        src:url("/_fonts/677f50b7-7744-49f9-b393-762e47e92c06.woff2") format("woff2"),url("/_fonts/509e9704-d9a2-45dc-90e6-d1ffcf5a8dcb.woff") format("woff");
    }
    @font-face{
        font-family:"Portfield W00 Regular";
        src:url("/_fonts/dc704669-bba3-4e4e-89d5-28302f44338a.woff2") format("woff2"),url("/_fonts/c30641ff-6d1e-4919-a593-5f9f908d72e2.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Light";
        src:url("/_fonts/d9fe41ee-4904-4a11-ba11-b61cd3be767f.woff2") format("woff2"),url("/_fonts/2fa30669-9bbd-4ced-912f-db94a367ed6c.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Reg";
        src:url("/_fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"),url("/_fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Smbd";
        src:url("/_fonts/ae47a7f5-89da-4879-b934-29722c3dd451.woff2") format("woff2"),url("/_fonts/70ae52ec-d89b-4c6a-9402-854ebe423c54.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Cd Light";
        src:url("/_fonts/228212a3-35ca-4dca-b8a4-b5ce4f0a489b.woff2") format("woff2"),url("/_fonts/b32c1fcd-5953-485f-97f6-db39b891e917.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Cd Reg";
        src:url("/_fonts/317fd159-159d-4870-a01f-ef9d5a71d5b3.woff2") format("woff2"),url("/_fonts/8e5dbeb3-4068-4196-83a4-c86fe35c8b65.woff") format("woff");
    }
    @font-face{
        font-family:"Proxima N W01 Cd Smbd";
        src:url("/_fonts/1c0840de-f0f0-478a-b326-93eab2f22001.woff2") format("woff2"),url("/_fonts/aa783977-3fc3-4514-813c-698b4970a505.woff") format("woff");
    }
    @font-face{
        font-family:"Quickpen W00 Regular";
        src:url("/_fonts/76c02472-2f52-43e1-a7ca-98605f0e41e4.woff2") format("woff2"),url("/_fonts/b03f9e76-83e3-4a01-a642-b0085ce9d73d.woff") format("woff");
    }
    @font-face{
        font-family:"TT Ramillas W01 ExtraLight";
        src:url("/_fonts/5270d6fd-a002-42c8-9cab-6a56696b6559.woff2") format("woff2"),url("/_fonts/306d882c-77d5-4fd2-bed3-ea869c9fb36d.woff") format("woff");
    }
    @font-face{
        font-family:"TT Ramillas W01 ExtraLight It";
        src:url("/_fonts/d0b17587-4be5-46a6-b850-e141b3716dbf.woff2") format("woff2"),url("/_fonts/489c297b-dbc5-4717-9224-1b3ad3d6fc57.woff") format("woff");
    }
    @font-face{
        font-family:"TT Ramillas W01 ExtraBold";
        src:url("/_fonts/9bb0ab7f-979a-400b-b1eb-1ea6bc83cab7.woff2") format("woff2"),url("/_fonts/acb31b98-4c42-4a31-9ae8-01b90a7ef057.woff") format("woff");
    }
    @font-face{
        font-family:"Recoleta W05 Thin";
        src:url("/_fonts/411a3a35-b349-450a-9265-c8eb7d8e2a31.woff2") format("woff2"),url("/_fonts/a2c6b38c-af8c-4af9-b4c6-96b302a00c24.woff") format("woff");
    }
    @font-face{
        font-family:"Recoleta W05 Light";
        src:url("/_fonts/9fa94dcd-7199-4950-96f9-64d4a2c8956d.woff2") format("woff2"),url("/_fonts/ae806a08-3dbf-488d-98be-9a095e667942.woff") format("woff");
    }
    @font-face{
        font-family:"Recoleta W05 Medium";
        src:url("/_fonts/dc6d46b5-4860-426e-b694-829311406cb2.woff2") format("woff2"),url("/_fonts/e6710639-8b79-4a63-a860-ae77c0a544b8.woff") format("woff");
    }
    @font-face{
        font-family:"Roelandt BT W01 Regular";
        src:url("/_fonts/395e733a-dfc7-4150-96a7-a20260558d1e.woff2") format("woff2"),url("/_fonts/b65e2a2b-072b-4c8e-9876-18682d18df8b.woff") format("woff");
    }
    @font-face{
        font-family:"Royal Castle W00 Regular";
        src:url("/_fonts/3656165d-b24b-472b-b6cd-2da9d46f47e8.woff2") format("woff2"),url("/_fonts/722bd6a4-9c0d-4210-9cbb-554b3f91d7ec.woff") format("woff");
    }
    @font-face{
        font-family:"SackersClassicRomanW01-";
        src:url("/_fonts/b13babc6-9363-48aa-bb41-4c337d851ad9.woff2") format("woff2"),url("/_fonts/3ba8c8be-5177-4201-8d03-28beb6158c24.woff") format("woff");
    }
    @font-face{
        font-family:"SackersGothicW01-Light";
        src:url("/_fonts/f8c59766-1a2b-426e-80fb-ebcb47f470c0.woff2") format("woff2"),url("/_fonts/558e1882-61fa-42c9-b946-2ff0b251d89e.woff") format("woff");
    }
    @font-face{
        font-family:"SackersScriptW01-Italia";
        src:url("/_fonts/78fe41ce-7aa7-4868-a1d1-1500cbfb02a0.woff2") format("woff2"),url("/_fonts/eee7f69b-d9a5-44ec-85e7-419d78182270.woff") format("woff");
    }
    @font-face{
        font-family:"SackersGothicW01-Medium";
        src:url("/_fonts/558c6f4c-6d4c-44b2-ae4f-8bc6b55f85ed.woff2") format("woff2"),url("/_fonts/e166de1f-75a3-44b0-b88e-a037e19243d2.woff") format("woff");
    }
    @font-face{
        font-family:"SackersGothicW01-Heavy";
        src:url("/_fonts/61ce039d-64f5-462d-bada-d8253c271b5c.woff2") format("woff2"),url("/_fonts/0e382226-2fd0-4217-b22f-6624a7afc569.woff") format("woff");
    }
    @font-face{
        font-family:"Salt and Spices W05 Regular";
        src:url("/_fonts/b45bc7ae-558c-4394-86b7-2b2a5197a4b3.woff2") format("woff2"),url("/_fonts/4c80b831-4c63-4471-b13b-466b5c308245.woff") format("woff");
    }
    @font-face{
        font-family:"Same Same But Different W00 Rg";
        src:url("/_fonts/8d6a589b-6e8c-4cd9-ab18-5e949ec6f6b6.woff2") format("woff2"),url("/_fonts/73df5378-ed96-42ed-849e-099e57fac198.woff") format("woff");
    }
    @font-face{
        font-family:"Saturday Script W05 Regular";
        src:url("/_fonts/7b6f06bb-6553-429a-8387-64b243d472db.woff2") format("woff2"),url("/_fonts/fda75274-1368-45b3-a863-dd37032c8e8c.woff") format("woff");
    }
    @font-face{
        font-family:"Silver South Script W05 Regula";
        src:url("/_fonts/93d20c6b-7c00-4d5c-8df1-faa6fbe5247d.woff2") format("woff2"),url("/_fonts/f5da77fc-96f9-451d-9187-44046bc32c0f.woff") format("woff");
    }
    @font-face{
        font-family:"Silver South Serif W05 Regular";
        src:url("/_fonts/87148c26-6c0c-4611-b6de-36aeb113c48b.woff2") format("woff2"),url("/_fonts/b1ec1d8d-00bf-424e-af04-9391e73d9836.woff") format("woff");
    }
    @font-face{
        font-family:"Staccato222W01-Regular";
        src:url("/_fonts/933dcb8c-54d2-4f33-b0bf-d5d05253a0c4.woff2") format("woff2"),url("/_fonts/f86c598d-3d65-4850-aabb-ad519e92b818.woff") format("woff");
    }
    @font-face{
        font-family:"Styled up Upright Tall W05 Rg";
        src:url("/_fonts/1b596eac-98e8-4c1b-af88-211e28a68a27.woff2") format("woff2"),url("/_fonts/0e62299d-2320-4fe8-a886-ecea8da71706.woff") format("woff");
    }
    @font-face{
        font-family:"Styled up Caps W05 Regular";
        src:url("/_fonts/26b2b862-dbb9-4e2e-84a0-950874e1793d.woff2") format("woff2"),url("/_fonts/63aaaaae-a1b9-4005-a7a3-09b713d7e88c.woff") format("woff");
    }
    @font-face{
        font-family:"The Hand W00 Regular";
        src:url("/_fonts/49baae53-a252-4603-a462-69ba5c81c4d2.woff2") format("woff2"),url("/_fonts/dbbb4e35-1aff-48a0-9163-fc0d6b3e02be.woff") format("woff");
    }
    @font-face{
        font-family:"The Hand W00 Bold";
        src:url("/_fonts/0e5707f8-ab3f-417a-b92a-87bce341bff7.woff2") format("woff2"),url("/_fonts/020ff5b7-dc07-4930-9fa7-cba611e9838b.woff") format("woff");
    }
    @font-face{
        font-family:"Amoret Sans W05 Regular";
        src:url("/_fonts/74ee4483-dc07-4c9e-8d9c-1effbcf033b9.woff2") format("woff2"),url("/_fonts/8b3be6b5-4605-463d-9984-cb6d604e42ad.woff") format("woff");
    }
    @font-face{
        font-family:"Amoret Script Alt W05 Regular";
        src:url("/_fonts/3aa6b234-5413-4d94-a9e1-6ffbafb8f1c7.woff2") format("woff2"),url("/_fonts/59016031-4f64-48f4-a38e-671b9e5f4117.woff") format("woff");
    }
    @font-face{
        font-family:"Throhand W01 Ink Roman";
        src:url("/_fonts/6460d0cd-ac3b-4459-9704-85250e961dae.woff2") format("woff2"),url("/_fonts/2e39fa92-83cc-4088-854f-62130e4f2e25.woff") format("woff");
    }
    @font-face{
        font-family:"Throhand W01 Ink Italic";
        src:url("/_fonts/b4f32034-192c-4ce5-b016-a1204fc6bc2b.woff2") format("woff2"),url("/_fonts/e3c1c719-1fa0-479c-b5bd-a221b2c6f9b6.woff") format("woff");
    }
    @font-face{
        font-family:"Turbinado W00 Regular";
        src:url("/_fonts/fe0df35a-3c97-4065-a2cf-3b65bfccfac8.woff2") format("woff2"),url("/_fonts/c891348e-b030-4543-8413-12c8455bb68a.woff") format("woff");
    }
    @font-face{
        font-family:"Venetian_301 W01 Demi_1121423";
        src:url("/_fonts/7f568bdc-5f0a-4813-8430-58ff14edf876.woff2") format("woff2"),url("/_fonts/1a2fe8fd-8aad-4db3-8498-c83323bf6c52.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Thin";
        src:url("/_fonts/6c535128-59e3-4ff1-81d0-cce3499a2be3.woff2") format("woff2"),url("/_fonts/0aba7ced-68ef-4e4f-8966-ba367a100ec5.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Thin Italic";
        src:url("/_fonts/f035ac15-49ff-450e-98c0-2e1b4de965b8.woff2") format("woff2"),url("/_fonts/12388af2-c995-4dd6-b791-1188512579e5.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Light";
        src:url("/_fonts/45845fa0-6683-45d2-b054-182dc85cf975.woff2") format("woff2"),url("/_fonts/0efe9b7a-e321-44f0-b433-dfeda792ee5d.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Light Italic";
        src:url("/_fonts/801c6219-445d-41ec-80c2-0dcba5b87188.woff2") format("woff2"),url("/_fonts/02f703ae-f0d3-466b-870b-1d21e3415d38.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Regular";
        src:url("/_fonts/a8d979c0-1ce8-4ca0-b30e-5baa0d46221f.woff2") format("woff2"),url("/_fonts/3f4cc581-6d9f-441a-94ad-478da242a016.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_60pt W01 Italic";
        src:url("/_fonts/38583a8d-8772-4503-8f71-715ac5ef25b0.woff2") format("woff2"),url("/_fonts/8ca3d518-b70f-47a2-96c5-05a47ed2696d.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_96pt W01 Light";
        src:url("/_fonts/06463a90-b3c2-4786-b719-f4c4c092606e.woff2") format("woff2"),url("/_fonts/bacda779-c108-4328-8320-58c454547993.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_96pt W01 Light Italic";
        src:url("/_fonts/f1229aee-2a9f-4950-a54c-97a2b9e7428a.woff2") format("woff2"),url("/_fonts/5a31f19a-c70b-478f-a156-681f5f764ef9.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_96pt W01 Medium";
        src:url("/_fonts/811709c4-de9c-45c1-ae1c-87c3a40db4db.woff2") format("woff2"),url("/_fonts/b37a7dfc-fae3-409a-b166-828b7fabefbc.woff") format("woff");
    }
    @font-face{
        font-family:"Walbaum_96pt W01 Medium Italic";
        src:url("/_fonts/1ad84a3a-c21d-4ed7-bc34-25ce1311e33c.woff2") format("woff2"),url("/_fonts/d9b32713-bb53-41ae-bd14-c05a05db8634.woff") format("woff");
    }
    @font-face{
        font-family:"Wild Spirit W05 Regular";
        src:url("/_fonts/7f7a0b74-ece9-483a-9f50-0fc9e5be0965.woff2") format("woff2"),url("/_fonts/66125617-b0e3-404f-9a60-1e737b437180.woff") format("woff");
    }
    @font-face{
        font-family:"Young Baroque W01";
        src:url("/_fonts/0a8ca20a-bbf6-46ec-b060-3c397624af25.woff2") format("woff2"),url("/_fonts/2b8e9c86-c57d-4cb9-8086-d1e1f190b68c.woff") format("woff");
    }


	@font-face{
		font-family:"Above the Beyond Script W05 Rg";
		src:url("/_fonts/449739a6-e118-4a5f-ba4e-90ccfc75ce15.woff2") format("woff2"),url("/_fonts/13118a37-8cca-4503-9719-731cf4654d05.woff") format("woff");
	}
	@font-face{
		font-family:"Above the Beyond Serif W05 It";
		src:url("/_fonts/b2cd6c62-62df-479d-b9d6-597dcffde178.woff2") format("woff2"),url("/_fonts/5ab86a85-1ee3-4078-a5e8-0b718346bcca.woff") format("woff");
	}
	@font-face{
		font-family:"Above the Beyond Serif W05 Rg";
		src:url("/_fonts/32c72846-4738-4040-9b8c-d495339a4a82.woff2") format("woff2"),url("/_fonts/8197d927-7e4c-424d-bbf5-e379dd6e2c53.woff") format("woff");
	}
	@font-face{
		font-family:"Abramo Script W05 Regular";
		src:url("/_fonts/85ac0d23-7a3d-4612-8776-e42d5d19ab9d.woff2") format("woff2"),url("/_fonts/2d40b1ce-a66f-42af-9370-985669f861bc.woff") format("woff");
	}
	@font-face{
		font-family:"Abramo Serif W05 Regular";
		src:url("/_fonts/6ff1d662-4a7b-44a3-bad0-4f72bb2864e4.woff2") format("woff2"),url("/_fonts/dfadf5b5-a033-41c6-a86c-66a0915a64dd.woff") format("woff");
	}
	@font-face{
		font-family:"Aire Roman Italic W05 Regular";
		src:url("/_fonts/f1bef6e4-c260-4a47-86ce-e9563ec74724.woff2") format("woff2"),url("/_fonts/97b7ff43-fd1e-47f3-932d-5cad109bb7a5.woff") format("woff");
	}
	@font-face{
		font-family:"Aire Roman W05 Regular";
		src:url("/_fonts/78b7824c-0161-4920-9384-55b253e55736.woff2") format("woff2"),url("/_fonts/b83a13e0-4bd6-4b01-90c4-4dd900d12124.woff") format("woff");
	}
	@font-face{
		font-family:"Balerno Serif W05 Light";
		src:url("/_fonts/2daaf50b-6e65-494d-bddb-878ec00dbb3e.woff2") format("woff2"),url("/_fonts/c5aa2db2-e135-4514-baa5-d34181a46f1e.woff") format("woff");
	}
	@font-face{
		font-family:"Baltazak W05 Regular";
		src:url("/_fonts/55584df0-faae-45fb-af2b-d5a3efb42619.woff2") format("woff2"),url("/_fonts/8e4880b5-6cf2-425a-883e-c3e79d067a8a.woff") format("woff");
	}
	@font-face{
		font-family:"Bambusa W01 Regular";
		src:url("/_fonts/702a3a47-d764-4ed8-9617-a610340820b3.woff2") format("woff2"),url("/_fonts/fbed532b-2fe7-4a63-ab86-d6179a7ea6fc.woff") format("woff");
	}
	@font-face{
		font-family:"Blastine W05 Regular";
		src:url("/_fonts/a5225aee-f860-4dc3-960d-c39d9b45653f.woff2") format("woff2"),url("/_fonts/03e361db-008b-4136-b01e-06a10fc4a963.woff") format("woff");
	}
	@font-face{
		font-family:"Cowboy Strong Stylish W05 Rg";
		src:url("/_fonts/a66b6dfd-37a2-4d63-a806-ff19ebfde382.woff2") format("woff2"),url("/_fonts/e3f302ec-7de1-4641-b6a7-b5e697acd84c.woff") format("woff");
	}
	@font-face{
		font-family:"Freight Big W01 Light Italic";
		src:url("/_fonts/72cba138-d811-4c64-81e4-5b21e52fca41.woff2") format("woff2"),url("/_fonts/6d267aa4-c43e-4f9b-b9db-09468fa42ec6.woff") format("woff");
	}
	@font-face{
		font-family:"Freight Big W01 Light Regular";
		src:url("/_fonts/6608d125-6c27-4495-aaa6-ee1665d2b251.woff2") format("woff2"),url("/_fonts/8bd246ef-a842-4f8f-b5e8-6e166196dfc1.woff") format("woff");
	}
	@font-face{
		font-family:"Hello Diandra W05 Regular";
		src:url("/_fonts/05f372f9-e6e5-4b3e-9b41-8c4f17a7ca79.woff2") format("woff2"),url("/_fonts/3a875fac-94f3-4f6a-83eb-cafd26cc524c.woff") format("woff");
	}
	@font-face{
		font-family:"La Mango W05 Regular";
		src:url("/_fonts/d81b09e6-3f1f-4a92-9ea7-89fc8b104599.woff2") format("woff2"),url("/_fonts/1d95525c-66a3-472d-a9ce-7d708ac380e4.woff") format("woff");
	}
	@font-face{
		font-family:"MinervaModern W05 Italic";
		src:url("/_fonts/ab7d58c7-c35b-45a2-855d-6e2e04f1f45c.woff2") format("woff2"),url("/_fonts/9118021c-884d-400c-a3a0-893e516615b6.woff") format("woff");
	}
	@font-face{
		font-family:"MinervaModern W05 Regular";
		src:url("/_fonts/b8bbda5e-077e-47e4-b1be-52865adb2608.woff2") format("woff2"),url("/_fonts/05366d09-8175-4e96-a39d-3823fb792d94.woff") format("woff");
	}
	@font-face{
		font-family:"Orpheus W01 Italic";
		src:url("/_fonts/40a36d98-bd56-41ce-ae76-88a1a1d25b9f.woff2") format("woff2"),url("/_fonts/8e903b08-f4d9-4df9-9e2c-a732855d166c.woff") format("woff");
	}
	@font-face{
		font-family:"Orpheus W01 Regular";
		src:url("/_fonts/24a59337-9a15-41bf-b423-368e9e3f5c5b.woff2") format("woff2"),url("/_fonts/6709e6c3-5acb-4e31-b1cb-3d2e098d5581.woff") format("woff");
	}
	@font-face{
		font-family:"Peachi W05 Thin";
		src:url("/_fonts/8af9586b-cc92-44ca-94fd-19eeb32aa796.woff2") format("woff2"),url("/_fonts/ee395795-90af-4ba6-bafe-ba2aff70d5ec.woff") format("woff");
	}
	@font-face{
		font-family:"Qene-G W05 Regular";
		src:url("/_fonts/939b7ff8-f800-4afd-a623-9b3769eb6333.woff2") format("woff2"),url("/_fonts/7748dce1-6ed9-4d08-af8b-3c7d5cea5465.woff") format("woff");
	}
	@font-face{
		font-family:"Quiche Sans W05 Light Italic";
		src:url("/_fonts/553ac075-5ea6-4b6a-8f1b-c9c3150aa4aa.woff2") format("woff2"),url("/_fonts/74d01310-9da5-411a-83d9-0b49aba0aa93.woff") format("woff");
	}
	@font-face{
		font-family:"Quiche Sans W05 Light";
		src:url("/_fonts/256d7052-54d0-47ed-b51e-835e50ac52a7.woff2") format("woff2"),url("/_fonts/db529caf-9812-44af-bba5-a5f1d8b649e5.woff") format("woff");
	}
	@font-face{
		font-family:"Sagitarius Signature Font W05";
		src:url("/_fonts/3de59a9b-248b-47c7-afb1-688d70c14283.woff2") format("woff2"),url("/_fonts/934ea187-ced3-47ce-b5ca-d4d27bcaea9f.woff") format("woff");
	}
	@font-face{
		font-family:"Sebastian Bobby Alt Slanted W05";
		src:url("/_fonts/70a5c8fd-f04b-4adf-81eb-8a9c050ca612.woff2") format("woff2"),url("/_fonts/ef455e21-3e74-4910-bf56-d2f10a5ff2c9.woff") format("woff");
	}
	@font-face{
		font-family:"Sebastian Bobby Slanted W05 Rg";
		src:url("/_fonts/32a26da5-e1c4-4638-b24c-42daa897de53.woff2") format("woff2"),url("/_fonts/91354bce-4812-44df-b865-1c0ea76da8d7.woff") format("woff");
	}
	@font-face{
		font-family:"Simple Serenity Script W05 Rg";
		src:url("/_fonts/58173024-fa99-4596-b615-fbff18ed4b85.woff2") format("woff2"),url("/_fonts/36183182-221f-46ed-8be8-4c7255e1653e.woff") format("woff");
	}
	@font-face{
		font-family:"Simple Serenity Serif W05 Rg";
		src:url("/_fonts/09f094b2-2c6f-4e65-95f7-17762c203840.woff2") format("woff2"),url("/_fonts/4aa3e1d6-a681-4d50-9cd4-356593b88382.woff") format("woff");
	}
	@font-face{
		font-family:"Winslow Script W05 Md Regular";
		src:url("/_fonts/be91ed22-2d43-4921-bf3a-13d06e2aae88.woff2") format("woff2"),url("/_fonts/b17127ff-5f34-4193-a606-83cf67397512.woff") format("woff");
	}
	@font-face{
		font-family:"Winslow Title Mod W05 Thin It";
		src:url("/_fonts/8562e8e5-69bc-44ca-a001-beba35dabac1.woff2") format("woff2"),url("/_fonts/630631d1-b23b-407b-ac6d-971fcb51f236.woff") format("woff");
	}
	@font-face{
		font-family:"Winslow Title Mod W05 Thin";
		src:url("/_fonts/c91f9fa1-2b3d-446a-a0d9-9fa80aee4cc9.woff2") format("woff2"),url("/_fonts/29eab93c-f3c3-4126-94c3-9d8d302883be.woff") format("woff");
	}
	@font-face{
		font-family:"Winslow Title W05 Regular It";
		src:url("/_fonts/bedeceef-f2d3-4d37-a6da-cea85bdfd8c1.woff2") format("woff2"),url("/_fonts/bb4cca13-6f76-4dcd-8ce1-a38548ab32f1.woff") format("woff");
	}
	@font-face{
		font-family:"Winslow Title W05 Regular";
		src:url("/_fonts/39d64178-d127-4e24-a46e-3ee8768acfd6.woff2") format("woff2"),url("/_fonts/7d39b346-72c3-4f3c-88a0-1bb88d03b462.woff") format("woff");
	}*/

/* CSS Document */
@-ms-viewport {
  width: device-width; }
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: top; 
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none; }
	
html {
  background: #ffffff;
  height: 100%;
  line-height: 1; }

body {
  font: 12px/1.5em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  min-width: 320px;
  color: #6c6c6c; }
  body b, body strong {
    font-weight: bold; }
  body i, body em {
    font-style: italic; }

ol, ul {
  list-style: none; }

a {
  text-decoration: none;
  color: inherit;
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  a:hover, a:active {
    opacity: 0.65; }
  a img {
    border: none; }

/* set Secondary color and font here */
p {
  margin-bottom: 10px;
  font: 12px/1.6em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  font-weight: normal;
  color: #6c6c6c; }

img {
  display: block;
  width: 100%;
  -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }
  
/* set coverpage logo/text */
h1.logo {
  display: -ms-flexbox;
  -ms-flex-direction: row;
  -ms-flex-wrap: nowrap;
  -ms-justify-content: center;
  -ms-flex-pack: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  -ms-align-content: center;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  -webkit-align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  font-weight: normal;
  line-height: 1em;
  padding: 30px 0;
  position: relative; }
  h1.logo img {
		width: auto;
		height: auto;
    max-height: 460px;
    max-width: calc(100% - 40px);
    margin: 0 auto; }

/* set monogram size, color, and font here */
h1.monogram {
  display: inline-block;
  padding-left: 60px;padding-right: 60px;	  font: 23px/1.2em "BrandonGrotW01-Regular";
    letter-spacing: 0px;
  font-weight: normal;
  color: #000000; }
/* if monogram is text, add additional padding to top and bottom */
/*
  h1.monogram.text {
  	padding-top: 15px;
  	padding-bottom: 15px; }
*/
  h1.monogram a {
    height: 100%;
    display: inline-block; }
/*
  h1.monogram a img {
	max-width: 275px;
  }
*/

/* set Title color and font here */
/* h2 font size is adjusted for browser width with media queries further below */
.App h2 {
	  font: 2.5833333333333em/1.3em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  font-weight: normal;
  margin: 0 auto 0.4em;
  color: #000000;
  display: inline-block; }

/* set Primary size, color and font here */
.App h3 {
  font: 16px/1.3em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  margin-bottom: 3px;
  font-weight: normal;
  color: #000000; }

/* set Secondary color and font here */
.App h4 {
  font: 13px/1.6em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  font-weight: normal;
  color: #6c6c6c; }

/* set Secondary color and font here? this h5 is only used on the rsvp section for entree selections */
.App h5 {
  font: 14px/44px "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  font-weight: normal;
  color: #6c6c6c; }

/* for: 'site by bliss & bone' in footer */
.App footer h6 {
  font: 9px/1em "AvenirNextLTW01-Regular";
  text-transform: uppercase;
  -webkit-text-size-adjust: none; }
.App footer h6 a {
display: inline-block;}
.buttonCont {
	display: inline-block;
	width: 100%;
/* 	white-space: nowrap; */
	position: relative;
	z-index: 10;
}
.button {
  display: block;
  width: 160px;
/*   max-width: 160px; */
  padding: 1px;
  margin: 10px auto;
  /* set Primary size, color and font here */
  font: 14px/1em "AvenirNextLTW01-Regular";
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  white-space: normal;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  /* set border color here, for gradient replace entire line */ 
  	  background: #ccb16f;
    -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
   }
		button::-moz-focus-inner {
	    border: 0;
	    padding: 0; }
    .button:hover, .button:active {
	    opacity: 0.65; }
	  .button span {
	    display: block;
	    padding: 5px 10px;
	    line-height: 1.5em;
	    background-color: #ffffff; }
		.button.rsvp {
			font-size: 20px;
			width: 240px;
		}
			.button.rsvp span {
				line-height: 1.7em;
			}

.error {
  color: #ff0000 !important; }

input {
	display: block;
  width: 100%;
  height: 44px;
  color: #6c6c6c;
  border-radius: 0;
  border-bottom: 1px solid #e2e2e2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 16px;
  font: 17px/44px "AvenirNextLTW01-Regular";
  font-weight: normal;
  outline: none;
  background-color: transparent; }
  
input.large {
    	font: 28px/1.3em "AvenirNextLTW01-Regular";  
  	color: #000000;
	height: 28px;
	min-height: 64px;
	/* text-indent: 10px; */
}	
	input.large.disable {
		pointer-events: none; }

select.hasPlaceholder {
  color: #6c6c6c;
  opacity: .5;
}

input::-webkit-input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
input:-moz-placeholder {
  color: #6c6c6c;
  opacity: .5; }
input::-moz-placeholder {
  color: #6c6c6c;
  opacity: .5; }
input:-ms-input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
input:input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
input:placeholder {
  color: #6c6c6c;
  opacity: .5; }
  
textarea::-webkit-input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
textarea:-moz-placeholder {
  color: #6c6c6c;
  opacity: .5; }
textarea::-moz-placeholder {
  color: #6c6c6c;
  opacity: .5; }
textarea:-ms-input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
textarea:input-placeholder {
  color: #6c6c6c;
  opacity: .5; }
textarea:placeholder {
  color: #6c6c6c;
  opacity: .5; }
  
input.error::-webkit-input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
input.error:-moz-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
input.error::-moz-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
input.error:-ms-input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
input.error:input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
input.error:placeholder {
  color: #ff0000 !important;
  opacity: 1; }
	

/* select:required:invalid {
  color: #6c6c6c;
  opacity: .5; } */
option[value=""][disabled] {
  display: none; }
option {
  color: #6c6c6c; }
  
textarea.error::-webkit-input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
textarea.error:-moz-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
textarea.error::-moz-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
textarea.error:-ms-input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
textarea.error:input-placeholder {
  color: #ff0000 !important;
  opacity: 1; }
textarea.error:placeholder {
  color: #ff0000 !important;
  opacity: 1; }
	
textarea {
  display: block;
  width: 100%;
  height: 44px;
  color: #6c6c6c;
  border-radius: 0;
  border-bottom: 1px solid #e2e2e2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0;
  margin-bottom: 16px;
  font: 17px/32px "AvenirNextLTW01-Regular";
  font-weight: normal;
  outline: none;
  resize: none;
  background-color: transparent; }

header {
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-justify-content: center;
  -ms-flex-pack: center;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
	width: 100%;
	min-height: 56px;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: center;
	line-height: 0;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #ffffff;
	z-index: 600;
	overflow: hidden; }
  header:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    /* set border color here, for gradient replace entire line */
  	  background: #ccb16f;
   }
  header img {
    width: auto;
    max-width: min(50vw, 400px);
    max-height: 100px; }
  header.singlePage {
	  z-index: 400; }
  header.header {
	  z-index: 600; }
	header.coverpage.cover, header.coverpage.bottom {
		background-color: transparent; }
	  header.coverpage h1, header.coverpage:after/* , main:not(.singlePage) header:after */ {
	    display: none; }
	  header.coverpage.header h1 {
	    display: inline-block; }



  #mm {
    position: fixed;
    top: 20px;
		right: 40px;    /* set menu alignment based on monogram */
    height: 16px;
    width: 22px;
    cursor: pointer;
    z-index: 1000; }
    #mm:before {
      content: "";
      display: block;
      border-top: solid 2px #000000;
      border-bottom: solid 2px #000000;
      height: 12px;
		  -webkit-transition: border-color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		  transition: border-color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    #mm:after {
      content: "";
      display: block;
      position: absolute;
      border-top: solid 2px #000000;
      width: 100%;
      left: 0;
      top: 50%;
      margin-top: -1px;
		  -webkit-transition: border-color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		  transition: border-color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    						    						    header.coverpage.home + #mm:before {
						      border-top-color: #ccb16f;
						      border-bottom-color: #ccb16f;
						    }
						    header.coverpage.home + #mm:after {
						      border-top-color: #ccb16f;
						    }
							    
    #mm.closeNav:before {
      border-bottom: none;
      height: 0;
      margin-top: 7px;
	    border-top-color: #000000 !important;
	    border-bottom-color: #000000 !important;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    #mm.closeNav:after {
	    border-top-color: #000000 !important;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    #mm:hover:before, #mm:hover:after {
      border-color: #9a9490 !important; }


#navContent {
  display: none;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 900; }
  #navContent:before {
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: #ffffff;
	  opacity: 0.95;
	  z-index: 0;
  }
  #navContent nav {
    display: inline-block;
	  text-align: center;
    position: relative;
		top: 0;
    z-index: 1;
    -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    #navContent nav a {
      display: block;
      font: 13px/2em "AvenirNextLTW01-Regular";
		letter-spacing: 0px;
      /* set Primary size, color and font here */
      color: #000000;
      margin-bottom: 8px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      #navContent nav a:hover, #navContent nav a:active, #navContent nav a.select {
        color: #9a9490; }
  #navContent.split {
	  display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -ms-justify-content: flex-start;
    -ms-flex-pack: start;
    -ms-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-align-content: stretch;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -webkit-align-items: stretch;
    -webkit-align-content: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    text-align: left;
	  position: fixed; }
	  #navContent.split:before {
		  display: none; }
	  #navContent.split .bgBox {
		  display: inline-block;
		  vertical-align: top;
		  width: 50%;
		  background-position: center center;
		  background-repeat: no-repeat;
		  background-size: cover;
		  -webkit-transform: translateY(100%);
		  transform: translateY(100%);
		  -webkit-transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	    transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
	  #navContent.split nav {
		  display: -ms-inline-flexbox;
      -ms-flex-direction: column;
      -ms-flex-wrap: nowrap;
      -ms-justify-content: center;
      -ms-flex-pack: center;
      -ms-align-items: center;
      -ms-flex-align: center;
      -ms-align-content: center;
      display: -webkit-inline-flex;
      -webkit-flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      -webkit-align-content: center;
      display: inline-flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
		  vertical-align: top;
		  width: 50%;
		  box-sizing: border-box;
		  padding: 4% 5.5% 4% 7%;
		  background-color: #ffffff;
		  -webkit-transform: translateY(-100%);
		  transform: translateY(-100%); }
		  #navContent.split nav .navScroll {
			  margin-right: auto;
			  padding-right: 20px;
			  overflow: auto;
				overflow-x: hidden;
				overflow-y: auto; }
		  #navContent.split.inverse nav {
			  /* background-color: #000000; */
			  }
			  #navContent.split nav a {
				  opacity: 0;
				  margin-right: auto;
				  text-align: left; }
				#navContent.split nav a:after {
					content: '';
					display: block;
					height: 1px;
					width: 100%;
					position: relative;
										  background: #ccb16f;
				  				  -webkit-transform: scaleX(0);
				  transform: scaleX(0);
				  -webkit-transform-origin: left center;
				  transform-origin: left center;
				  -webkit-transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			    transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
			  #navContent.split.inverse nav a {
				  color: #ffffff; }
				#navContent.split nav a.active {
					opacity: 0.65; }
				  #navContent.split nav a.active:hover, #navContent.split nav a.active:active, #navContent.split nav a.active.select {
					  opacity: 1;
		        color: #000000; }
				  #navContent.split.inverse nav a.active:hover, #navContent.split.inverse nav a.active:active, #navContent.split.inverse nav a.active.select {
					  opacity: 1;
		        color: #ffffff; }
				  #navContent.split nav a.active:hover:after, #navContent.split nav a.active:active:after, #navContent.split nav a.active.select:after {
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1); }
				  #navContent.split.inverse nav a.active:hover:after, #navContent.split.inverse nav a.active:active:after, #navContent.split.inverse nav a.active.select:after {
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1); }
		#navContent.split.active .bgBox {
			-webkit-transform: translateY(0);
			transform: translateY(0); }
		#navContent.split.active nav {
			-webkit-transform: translateY(0);
			transform: translateY(0); }
		#navContent.split.inverse + header + #mm.closeNav {
			color: #ffffff !important; }
		#navContent.split.inverse + header + #mm.closeNav:before {
			border-top-color: #ffffff !important;
			border-bottom-color: #ffffff !important; }
		#navContent.split.inverse + header + #mm.closeNav:after {
			border-top-color: #ffffff !important; }
main h1.monogram a.select {
	opacity: 1.0;
}
main h1.monogram a.select:hover {
	opacity: .65;
}
main section {
  text-align: center;
/*   min-height: 400px; */
  margin: 0 auto;
  display: none;
  overflow: hidden; }
  main.singlePage section {
	  display: block; }
	  main.singlePage section.coverpage.cover, main.singlePage section.coverpage.bottom, main.singlePage section.gallery-collage, main.singlePage section.gallery-thumbnails {
	    display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex; }
/* 	  main.singlePage section:not(.home) + section { */
		main.singlePage section + section {
		  margin-top: 0 !important }
	main:not(.singlePage) section .section:not(.full):not(.split) .sectionCont:last-child, main:not(.singlePage) section.gallery .section:last-child, main:not(.singlePage) section.gallery .credit, main:not(.singlePage) section.gallery-collage .credit, main:not(.singlePage) section.gallery-thumbnails .credit, main:not(.singlePage) section.rsvp form {
		margin-bottom: 40px; }
	main.singlePage section:last-of-type .section:not(.full):not(.split) .sectionCont:last-child, main.singlePage section.gallery:last-of-type, main.singlePage section.gallery-collage:last-of-type, main.singlePage section.gallery-thumbnails:last-of-type, main.singlePage section.rsvp:last-of-type, main.singlePage section:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery-collage:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery-thumbnails:last-of-type[style*="display: none"] + .spacer,  main.singlePage section.rsvp:last-of-type[style*="display: none"] + .spacer {
		margin-bottom: 40px; }
  main section .sectionCont .imgCont {
	  display: block;
    position: relative; }
    main section .sectionCont .imgCont > iframe {
	    display: block;
	    width: 100%;
	    margin: 0 auto; }
	main section .textCont > .subsection {
			margin-bottom: 30px; }

    main section .textCont > .subsection .palette{
            width: 50%;
            display: block;
        }

    main section .textCont > .subsection .map{
            width: 100%;
            display: block;
        }

    main section .textCont > .subsection .indian-attire{
        width: 50%;
        display: block;
    }
	main section .textCont > .subsection:last-child, main section .textCont > .subsection *:last-child {
		margin-bottom: 0 !important; }
  main section .sectionCont > .textCont {
	  max-width: 700px;
	  padding-left: 40px;
	  padding-right: 40px;
	  margin: 0 auto 20px; }
    main section .imgCont .textCont {
		  margin: 0 auto;
      width: 100%;
      padding: 30px 20px;
			position: relative;
      box-sizing: border-box;
      display: -ms-flexbox;
      -ms-flex-direction: column;
      -ms-flex-wrap: nowrap;
      -ms-justify-content: center;
      -ms-flex-pack: center;
      -ms-align-items: center;
      -ms-flex-align: center;
      -ms-align-content: center;
      display: -webkit-flex;
      -webkit-flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      -webkit-align-content: center;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center; }
		main section .imgCont.videoCont .textCont {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0; }
      main section .imgCont .textCont h2, main section .imgCont .textCont h3, main section .imgCont .textCont h4, main section .imgCont .textCont p {
	      width: 100%;
	      max-width: 700px;
	      margin-left: auto;
	      margin-right: auto; } 
	      main section .imgCont .textCont *:last-child {
		      margin-bottom: 0 !important; }
      main section .imgCont .textCont .buttonCont {
	      max-width: 720px;
	      margin-left: auto;
	      margin-right: auto; }
      main section .imgCont .textCont .button {
	      background: none;
	      padding: 0; }
	      main section .imgCont .textCont .button span {
		      background-color: transparent;
		      border: 1px solid #000000; }
/*
  main section .subsection {
  	max-width: 700px;
  	margin-left: auto;
  	margin-right: auto;
    margin-bottom: 45px; }
*/
/*
    main section .subsection h3 + .button, main section .subsection h4 + .button, main section .subsection p + .button {
      margin-top: 26px; }
*/
/*
    main section .subsection p {
      margin-top: 20px;
      margin-bottom: 0; }
*/
  main section.coverpage {
    box-sizing: border-box;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
	  padding: 0 40px;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    z-index: 500; }
    main section.coverpage.singleCoverpage {
	    z-index: 0; }
	    main section.coverpage.singleCoverpage.addPadd {
		    margin-top: 40px !important; }
    main section.coverpage .bgBox {
	    width: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    background-repeat: no-repeat;
	    background-position: center center;
	    background-size: cover; }
	  main section.coverpage .bgBoxMap {
		  height: 90vh; }
    main section.coverpage.halign-center {
      -ms-justify-content: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
    main section.coverpage.halign-left {
      -ms-justify-content: flex-start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start; }
      main section.coverpage.halign-left h1.logo {
	      text-align: left; }
    main section.coverpage.halign-right {
      -ms-justify-content: flex-end;
      -ms-flex-pack: end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end; }
      main section.coverpage.halign-right h1.logo {
	      text-align: right; }
    main section.coverpage.valign-middle {
      -ms-align-items: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
    main section.coverpage.valign-top {
      -ms-align-items: flex-start;
      -ms-flex-align: start;
      -webkit-align-items: flex-start;
      align-items: flex-start; }
    main section.coverpage.valign-bottom {
      -ms-align-items: flex-end;
      -ms-flex-align: end;
      -webkit-align-items: flex-end;
      align-items: flex-end; }
    main section.coverpage.cover {
	    margin-top: 0 !important; }
      main section.coverpage.cover h1.logo {
	      display: inline-block;
			  max-width: 95%;
			  box-sizing: content-box; }
    main section.coverpage.bottom {
      -ms-align-items: flex-end !important;
      -ms-flex-align: end !important;
      -webkit-align-items: flex-end !important;
      align-items: flex-end !important;
      padding: 0;
      margin-top: 0 !important; }
			main section.coverpage.bottom h1.logo img, main section.coverpage.bottom h1.logo span {
				display: inline-block;
				margin: 0;
				max-height: 90%;
				max-width: 100%;
				margin: auto; }
      main section.coverpage.bottom h1.logo {
        margin: 0;
        padding: 0 40px;
        width: 100%;
        max-width: none;
        height: 33%;
        background-color: #ffffff; }
      main section.coverpage.bottom.halign-left h1.logo {
	      -ms-justify-content: flex-start;
	      -ms-flex-pack: start;
	      -webkit-justify-content: flex-start;
	      justify-content: flex-start; }
      main section.coverpage.bottom.halign-right h1.logo {
	      -ms-justify-content: flex-end;
	      -ms-flex-pack: end;
	      -webkit-justify-content: flex-end;
	      justify-content: flex-end; }
				main section.coverpage.bottom.halign-left h1.logo span {
					margin-left: 0; }
				main section.coverpage.bottom.halign-right h1.logo span {
					margin-right: 0; }
    main section.coverpage.header {
	    display: block;
	    height: auto;
	    position: relative;
/* 	    bottom: auto; */ }
			main section.coverpage.header.colorOnly {
				height: 90vh; }
	    main section.coverpage.header h1.logo {
		    position: absolute;
		    top: 0;
		    left: 40px;
		    right: 40px;
		    bottom: 0;
		    z-index: 100; }
		    main section.coverpage.header.halign-center h1.logo {
		      -ms-justify-content: center;
		      -ms-flex-pack: center;
		      -webkit-justify-content: center;
		      justify-content: center; }
		    main section.coverpage.header.halign-left h1.logo {
		      -ms-justify-content: flex-start;
		      -ms-flex-pack: start;
		      -webkit-justify-content: flex-start;
		      justify-content: flex-start; }
		    main section.coverpage.header.halign-right h1.logo {
		      -ms-justify-content: flex-end;
		      -ms-flex-pack: end;
		      -webkit-justify-content: flex-end;
		      justify-content: flex-end; }
		    main section.coverpage.header.valign-middle h1.logo {
		      -ms-align-items: center;
		      -ms-flex-align: center;
		      -webkit-align-items: center;
		      align-items: center; }
		    main section.coverpage.header.valign-top h1.logo {
		      -ms-align-items: flex-start;
		      -ms-flex-align: start;
		      -webkit-align-items: flex-start;
		      align-items: flex-start; }
		    main section.coverpage.header.valign-bottom h1.logo {
		      -ms-align-items: flex-end;
		      -ms-flex-align: end;
		      -webkit-align-items: flex-end;
		      align-items: flex-end; }
					main section.coverpage.header h1.logo img, main section.coverpage.header h1.logo span {
						display: inline-block;
						margin-left: 40px;
						margin-right: 40px; }
					main section.coverpage.header .bgBox {
						width: auto;
						position: static; }
/*
			    main section.coverpage.header.halign-left h1.logo img {
			    margin-left: 0; }
			    main section.coverpage.header.halign-right h1.logo img {
			    margin-right: 0; }
			    main section.coverpage.header h1.logo img, main section.coverpage.valign-top h1.logo img {
				    max-width: 50vw;
				    max-height: 100px; }
				    main section.coverpage.header.halign-center h1.logo, main section.coverpage.valign-top.halign-center h1.logo {
					    padding-left: 60px;
					    padding-right: 60px; }
					  main section.coverpage.header.halign-right h1.logo, main section.coverpage.valign-top.halign-right h1.logo {
					    padding-left: 60px; }
					  main section.coverpage.header.halign-left h1.logo, main section.coverpage.valign-top.halign-left h1.logo {
					    padding-right: 60px; }
*/
	
			main section .section .sectionCont .imgCont .mediaCont, main section .section .sectionCont .imgCont .bgBox {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
			}
			main section .section .sectionCont .imgCont .bgBox {
				background-position: center center;
				background-size: cover;
				background-repeat: no-repeat;
			}
		  main section .section.full .sectionCont.small .imgCont:not(.videoCont), main section .section.full .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: max(30vw, 325px); }
		  main section .section.full .sectionCont.medium .imgCont:not(.videoCont), main section .section.full .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: max(66.7vw, 360px); }
		  main section .section.full .sectionCont.large .imgCont:not(.videoCont), main section .section.full .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: 133.4vw; }
		  main section .section.large .sectionCont.small .imgCont:not(.videoCont), main section .section.large .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: clamp(300px, 34.4vw, 338px); }
			main section .section.large .sectionCont.medium .imgCont:not(.videoCont), main section .section.large .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: clamp(335px, 61.2vw, 600px); }
			main section .section.large .sectionCont.large .imgCont:not(.videoCont), main section .section.large .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: min(122.5vw, 1200px); }
		  main section .section.split .sectionCont.small .imgCont:not(.videoCont), main section .section.split .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: 33.3vw; }
		  .isWin main section .section.split .sectionCont.small .imgCont:not(.videoCont), .isWin main section .section.split .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: calc(33.3vw - 8px); }
		  main section .section.split .sectionCont.medium .imgCont:not(.videoCont), main section .section.split .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: 50vw; }
		  .isWin main section .section.split .sectionCont.medium .imgCont:not(.videoCont), .isWin main section .section.split .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: calc(50vw - 8px); }
		  main section .section.split .sectionCont.large .imgCont:not(.videoCont), main section .section.split .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: 75vw; }
		  .isWin main section .section.split .sectionCont.large .imgCont:not(.videoCont), .isWin main section .section.split .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: calc(75vw - 8px); }
		  main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: min(20.9vw, 272px); }
		  .isWin main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont), .isWin main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: min(20.9vw - 8px, 272px); }
		  main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: min(28.9vw, 375px); }
		  .isWin main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont), .isWin main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: min(375px, 375px); }
		  main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: min(38.4vw, 500px); }
		  .isWin main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont), .isWin main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: min(38.4vw - 8px, 500px); }
		  main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: min(23.6vw, 272px); }
		  .isWin main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont), .isWin main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: min(23.6vw - 8px, 272px); }
		  main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: min(32.5vw, 375px); }
		  .isWin main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont), .isWin main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: min(32.5vw - 8px, 375px); }
		  main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: min(43.4vw, 499px); }
		  .isWin main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont), .isWin main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: min(43.4vw - 8px, 499px); }
		  main section .section.stagger_r .sectionCont.small .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.small .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.small .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.small .imgCont:not(.videoCont) .textCont {
		    min-height: 287px; }
		  main section .section.stagger_r .sectionCont.medium .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.medium .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.medium .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		    min-height: 430px; }
		  main section .section.stagger_r .sectionCont.large .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.large .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.large .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.large .imgCont:not(.videoCont) .textCont {
		    min-height: 645px; }
			main section.rsvp .imgCont {
				min-height: max(37.5vw, 325px); }
				
			main section .section.full .sectionCont.small .imgCont.doNotCrop:not(.videoCont), main section .section.full .sectionCont.small .imgCont.doNotCrop:not(.videoCont) .textCont {
				min-height: 37.5vw; }
			main section .section.full .sectionCont.medium .imgCont.doNotCrop:not(.videoCont), main section .section.full .sectionCont.medium .imgCont.doNotCrop:not(.videoCont) .textCont {
				min-height: 66.7vw; }
			main section .section.large .sectionCont.small .imgCont.doNotCrop:not(.videoCont), main section .section.large .sectionCont.small .imgCont.doNotCrop:not(.videoCont) .textCont {
				min-height: min(34.4vw, 338px); }
			main section .section.large .sectionCont.medium .imgCont.doNotCrop:not(.videoCont), main section .section.large .sectionCont.medium .imgCont.doNotCrop:not(.videoCont) .textCont {
				min-height: min(61.2vw, 600px); }
					    
	main section .section .sectionCont .imgCont.textBelow {
		margin-bottom: 20px; }
		main section .section.large, main section .section.boxes2, main section .section.boxes3, main section .section.stagger_l, main section .section.stagger_r {
			padding: 40px 40px 0; }
		main section .section.boxes2 .sectionCont > .textCont, main section .section.boxes3 .sectionCont > .textCont {
			padding-left: 20px;
			padding-right: 20px; }
		main section .section.boxes3, main section .section.stagger_l .sectionCont > .textCont, main section .section.stagger_r .sectionCont > .textCont {
			margin: 0; }
		main section .section.large .button, main section .section.full .button {
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px; }
			main section .section.large .sectionCont + .sectionCont, main section .section.large .sectionCont + style + .sectionCont {
				margin-top: 40px;
				padding-top: 40px;
				position: relative; }
				main section .section.large .sectionCont + .sectionCont:before, main section .section.large .sectionCont + style + .sectionCont:before {
					content: "";
					width: 100%;
					height: 1px;
										  background: #ccb16f;
				  					position: absolute;
					top: 0;
					left: 0 }
		main section .section.large .sectionCont {
			display: block;
			max-width: 900px;
			margin: 0 auto; }
	    
		main section .section.boxes2, main section .section.boxes3 {
      display: -ms-flexbox;
      -ms-flex-direction: row;
      -ms-flex-wrap: wrap;
      -ms-justify-content: center;
      -ms-flex-pack: center;
      -ms-align-items: flex-start;
      -ms-flex-align: start;
      display: -webkit-flex;
      -webkit-flex-direction: row;
      -webkit-flex-wrap: wrap;
      -webkit-justify-content: center;
      -webkit-align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      vertical-align: middle;
			max-width: 1300px;
			box-sizing: border-box;
			margin: 0 auto; }
			main section .section.boxes3 {
				display: -ms-inline-flexbox;
	      display: -webkit-inline-flex;
	      display: inline-flex;
	      width: 100%;
				margin: 0 -15px; }
		main section .section.boxes2 .sectionCont, main section .section.boxes3 .sectionCont {
			display: inline-block;
			vertical-align: top;
			width: 35%;
			max-width: 375px;
			margin: 0 6.1% 30px; }
			main section .section.boxes2 .sectionCont:nth-last-child(1), main section .section.boxes2 .sectionCont:nth-last-child(2), main section .section.boxes3 .sectionCont:nth-last-child(1), main section .section.boxes3 .sectionCont:nth-last-child(2), main section .section.boxes3 .sectionCont:nth-last-child(3) {
			margin-bottom: 0; }
		main section .section.boxes3 .sectionCont {
			width: calc(33.333% - 30px);
			margin-left: 15px;
			margin-right: 15px; }
			
    main section .section.boxes2 .sectionCont .imgCont, main section .section.boxes3 .sectionCont .imgCont {
	    box-sizing: border-box;
/*       padding: 1px; */
		  			  background: #ccb16f;
		        }
      main section .section.boxes2 .sectionCont .imgCont .bgColor, main section .section.boxes3 .sectionCont .imgCont .bgColor {
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px; }
      main section.squares .imgCont img {
        position: relative; }
        
		main section .section.stagger_l .sectionCont + .sectionCont, main section .section.stagger_r .sectionCont + .sectionCont, main section .section.stagger_l .sectionCont + style + .sectionCont, main section .section.stagger_r .sectionCont + style + .sectionCont {
				margin-top: 20px;
				padding-top: 20px; }
/*
		main section .section.stagger_l + .section.stagger_r, main section .section.stagger_r + .section.stagger_l {
				padding-top: 0 !important; }
*/

                .carousel-control-next, .carousel-control-prev {
                    color: #668;
                }

                .carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
                    color: #668;
                }

                .carousel-control-next-icon{
                    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23668' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
                }

                .carousel-control-prev-icon{
                    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23668' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
                }
  
  main section .section.stagger_l .sectionCont, main section .section.stagger_r .sectionCont {
    display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -ms-justify-content: flex-start;
    -ms-flex-pack: start;
    -ms-align-items: center;
    -ms-flex-align: center;
    -ms-align-content: center;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-align-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    max-width: 1190px;
    margin: 0 auto; }
    main section .section.stagger_l .sectionCont .imgCont, main section .section.stagger_r .sectionCont .imgCont {
      display: inline-block;
      vertical-align: middle;
      width: min(100%, 430px);
			min-width: 430px; }
      /* main section .section.stagger_l .sectionCont.large .imgCont .textCont, main section .section.stagger_r .sectionCont.large .imgCont .textCont {
	      max-height: 643px; }
	    main section .section.stagger_l .sectionCont.medium .imgCont .textCont, main section .section.stagger_r .sectionCont.medium .imgCont .textCont {
	      max-height: 430px; }
	    main section .section.stagger_l .sectionCont.small .imgCont .textCont, main section .section.stagger_r .sectionCont.small .imgCont .textCont {
	      max-height: 287px; } */
    main section .section.stagger_l .sectionCont > .textCont, main section .section.stagger_r .sectionCont > .textCont {
	    display: -ms-inline-flexbox;
	    -ms-flex-direction: column;
	    -ms-justify-content: center;
	    -ms-flex-pack: center;
	    display: -webkit-inline-flex;
	    -webkit-flex-direction: column;
	    -webkit-justify-content: center;
	    display: inline-flex;
	    flex-direction: column;
	    justify-content: center;
      max-width: 400px;
      text-align: left; }
      main section .section.stagger_l .sectionCont > .textCont *, main section .section.stagger_r .sectionCont > .textCont * {
	      margin-left: 0;
	      margin-right: 0; }

      main section .section.stagger_l .sectionCont > .textCont *, main section .section.stagger .sectionCont > .textCont .palette {
          margin-left: 25%; }

        main section main section .section.stagger .sectionCont > .textCont .map {
          margin-left: 25%; }


    main section .section.stagger_l .sectionCont > .textCont *, main section .section.stagger .sectionCont > .textCont .indian-attire {
          margin-left: 25%; }

    main section .section.stagger_l .sectionCont .imgCont.textBelow, main section .section.stagger_r .sectionCont .imgCont.textBelow {
			margin-bottom: 0; }
    main section .section.stagger_l .sectionCont .textCont.textOnly {
	    padding-left: 0; }
    main section .section.stagger_r .sectionCont .textCont.textOnly {
			padding-right: 0; }
    main section .section.stagger_r .sectionCont {
	    -ms-flex-direction: row-reverse;
	    -webkit-flex-direction: row-reverse;
	    flex-direction: row-reverse; }
    main section .section.stagger_r .sectionCont > .textCont {
      text-align: right; }
      main section .section.stagger_r .sectionCont > .textCont .buttonCont .button {
	      margin-right: 0;
	      margin-left: auto; }
      
	main section .section.split {
		display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -ms-justify-content: flex-start;
    -ms-flex-pack: start;
    -ms-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-align-content: stretch;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -webkit-align-items: stretch;
    -webkit-align-content: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    padding-top: 40px; }
	  main section .section.split .sectionCont {
	    display: inline-block;
			vertical-align: top;
	    width: 50%; }
			main section .section.split .sectionCont > .textCont, main section .section.full .sectionCont > .textCont {
				margin-bottom: 20px; }
			main section .section.split .sectionCont > .textCont {
				padding-left: 60px;
				padding-right: 60px; }
				main section .section.split .sectionCont .imgCont .textCont {
					padding-left: 40px;
					padding-right: 40px; }
	main section .section.full {
		padding-top: 40px; }
		main section .section.full + .section.full, main section .section.full + .section.split, main section .section.split + .section.split, main section .section.split + .section.full, main section .section.full:not(.addPadd):first-child, main section .section.split:not(.addPadd):first-child, main section.coverpage + section .section.full, main section.coverpage + section .section.split, main section .section.full + style + .section.full, main section .section.full + style + .section.split, main section .section.split + style + .section.split, main section .section.split + style + .section.full, main section .section.full:not(.addPadd):first-child, main section .section.split:not(.addPadd):first-child, main section.coverpage + style + section .section.full, main section.coverpage + style + section .section.split {
			padding-top: 0 !important; }
		main section.rsvp.topImage:not(.addPadd):first-child {
			padding-top: 10px !important; }
		main section.rsvp.topImage:not(.addPadd):first-child > .imgCont, main section.rsvp.topImage:not(.addPadd):first-child > .mediaCont {
			top: 0 !important; }
/*
			main section .section.full + .section.large, main section .section.full + .section.boxes2, main section .section.full + .section.boxes3, main section .section.full + .section.stagger_l, main section .section.full + .section.stagger_r, main section .section.split + .section.large, main section .section.split + .section.boxes2, main section .section.split + .section.boxes3, main section .section.split + .section.stagger_l, main section .section.split + .section.stagger_r, main section .section.addMargin {
				margin-top: 40px; }
*/
		main section .section.full .sectionCont > .textCont.textOnly, main section .section.split .sectionCont > .textCont.textOnly {
			padding-top: 40px; }
/*
	    main section .section.split .sectionCont.singleCont {
		    width: 50%; }
	    main section .section.split .sectionCont .imgCont.textBelow {
				margin-bottom: 0; }
			main section .section.split .sectionCont .imgCont, main section .section.split .sectionCont > .textCont {
				display: inline-block;
				vertical-align: top;
				width: 50%; }
				main section .section.split .sectionCont.singleCont .imgCont, main section .section.split .sectionCont.singleCont > .textCont {
					width: 100%; }
*/
/*
			main section .section.split .sectionCont.small .imgCont, main section .section.split .sectionCont.small > .textCont {
				height: 464px; }
			main section .section.split .sectionCont.medium .imgCont, main section .section.split .sectionCont.medium > .textCont {
				height: 640px; }
			main section .section.split .sectionCont.large .imgCont, main section .section.split .sectionCont.large > .textCont {
				height: 853px; }
*/
/*
			main section .section.split .sectionCont .imgCont .bgBox {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				background-position: center center;
				background-size: cover; }
				main section .section.split .sectionCont > .textCont, main section .section.full .sectionCont > .textCont, main section .section.large .sectionCont > .textCont  {
					margin-bottom: 40px; }
*/
/*
			   main section .section.split .sectionCont > .textCont {
			    display: -ms-flexbox;
			    -ms-flex-direction: column;
			    -ms-flex-wrap: wrap;
			    -ms-justify-content: center;
			    -ms-flex-pack: center;
			    -ms-align-items: center;
			    -ms-flex-align: center;
			    -ms-align-content: center;
			    display: -webkit-flex;
			    -webkit-flex-direction: column;
			    -webkit-flex-wrap: wrap;
			    -webkit-justify-content: center;
			    -webkit-align-items: center;
			    -webkit-align-content: center;
			    display: flex;
			    flex-direction: column;
			    flex-wrap: wrap;
			    justify-content: center;
			    align-items: center;
			    align-content: center; }
*/

  
  main section.gallery {
    max-width: 900px;
    padding: 40px 20px 0; }
    main section.gallery .imgCont {
	    display: block; }
    main section.gallery .section + .section {
      margin-top: 20px; }
			main section.gallery .credit {
				width: 100%;
				margin-top: 20px; }
  main section.gallery-collage {
	  display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -ms-justify-content: flex-start;
    -ms-flex-pack: start;
    -ms-align-items: flex-start;
    -ms-flex-align: start;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -webkit-align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
/*     max-width: 1360px; */
		max-width: 1020px;
		padding: 40px 20px 0;
    text-align: center; }
    main section.gallery-collage .section {
      display: inline-block;
      vertical-align: top;
      width: 336px;
      margin-top: 0;
      margin-right: 5px;
      margin-bottom: 5px;
      -moz-transition-property: all;
      -o-transition-property: all;
      -webkit-transition-property: all;
      transition-property: all;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
			main section.gallery-collage .credit {
				width: 100%;
				margin-top: 10px; }
  main section.gallery-thumbnails {
	  display: -ms-flexbox;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -ms-justify-content: flex-start;
    -ms-flex-pack: start;
    -ms-align-items: flex-start;
    -ms-flex-align: start;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -webkit-align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1190px;
    padding: 40px 20px 0;
    text-align: center; }
    main section.gallery-thumbnails .section {
      display: inline-block;
      vertical-align: top;
			width: 31.45%;
      margin-top: 0;
			margin-right: 2.825%;
			margin-bottom: 2.8vw;
      -moz-transition-property: all;
      -o-transition-property: all;
      -webkit-transition-property: all;
      transition-property: all;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
      main section.gallery-thumbnails .section:nth-child(3n+0) {
				margin-right: 0; }
			main section.gallery-thumbnails .section:nth-last-of-type(1), main section.gallery-thumbnails .section:nth-last-of-type(2), main section.gallery-thumbnails .section:nth-last-of-type(3) {
				margin-bottom: 0 !important;	}
			main section.gallery-thumbnails .credit {
				width: 100%;
				margin-top: 20px; }
  main section.rsvp {
    padding: 10px 0 0;
    min-width: 300px;
    text-align: left;
    position: relative; }
  main section.rsvp.rsvpOne {
	  padding-top: 40px; }
  main section.rsvp.topImage.addPadd {
    padding-top: 50px;
	 	margin-top: 40px !important; }
    main section.rsvp form {
	    max-width: 700px;
	    margin: 0 auto;
	    padding: 0 20px;
	    position: relative; }
	    main section.rsvp > .imgCont, main section.rsvp > .mediaCont {
		    width: 100%;
		    position: absolute;
		    top: 0;
		    left: 0; }
				main section.rsvp > .imgCont .bgBox {
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					background-position: center center;
					background-size: cover;
					background-repeat: no-repeat; }
		  main section.rsvp.topImage.addPadd > .imgCont, main section.rsvp.topImage.addPadd > .mediaCont {
			  top: 0px; }
	    main section.rsvp > .mediaCont {
		    height: 37.5vw; }
    main section.rsvp .content {
	    margin: 10px 0 0;
	    padding: 26px 80px 0; }
	  main section.rsvp .content + .content {
	    background-color: #ffffff;
	    padding: 50px 80px; }
			main section.rsvp .content + .content .group:not(.guest):first-of-type h3 {
				margin-top: 0; }
    main section.rsvp form.rsvpImage .content {
	    margin: 50px 0 0; }
	  main section.rsvp form.rsvpImage .content h4 + .line {
	    display: none; }
    main section.rsvp h4 {
      text-align: center;
      padding-bottom: 20px; }
    main section.rsvp .line {
	    height: 5px;
      margin-bottom: 10px;
      			  background: #ccb16f;
		   }
		main section.rsvp .button {
			margin-top: 40px;	}
		main section.rsvp .confirmText {
			position: relative;
		 	margin-bottom: 60px; }
		main section.rsvp .confirmText:after {
			display: none; }
	main section.rsvp h2 {
		width:100%;
		text-align:center;
		margin-bottom: 0px;}
    main section.rsvp h3 {
    	font: 16px/1.3em "AvenirNextLTW01-Regular";  
  	  letter-spacing: 0px;
      line-height: 40px;
      margin-top: 32px;
      margin-bottom: 12px; }
		main section.rsvp h3.topTitle {
			text-align: center;
			line-height: 1.3em;
			margin-top: 0;
			margin-bottom: 5px; }
		main section.rsvp h3.topTitle:after {
			display: none; }
		main section.rsvp h3.topTitle.self {
			text-align: left; }
		main section.rsvp input.error, main section.rsvp select.error, main section.rsvp textarea.error {
			border-bottom-color: #ff0000 !important; }
		main section.rsvp select.hasPlaceholder.error {
			opacity: 1;
		}
/*
		main section.rsvp .inputText {
			padding: 6px 0 5px;
		  margin-bottom: 16px;
		  border-bottom: 1px solid #e2e2e2;
	    -moz-box-sizing: border-box;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box; }
			main section.rsvp .inputText > span {
				display: block;
				max-width: 67%;
			  color: #6c6c6c;
			  font: 17px/32px "AvenirNextLTW01-Regular";
			  font-weight: normal; }
		  main section.rsvp .inputText .radioButtons {
			  float: right;
			  padding: 2px 0 5px; }
*/
    main section.rsvp .group {
      margin-bottom: 40px; }
      main section.rsvp .group.guest + .group.guest {
      padding-top:45px;}
    main section.rsvp #confirm {
      display: none;
      text-align: center;
      margin: 30px auto; }
    main section.rsvp #loading {
      background: url(https://myblissandbone.com/_images/loader_wht.gif) no-repeat center center;
      height: 30px;
      width: 80px;
      margin: 30px auto; }
    main section.rsvp h5.mealName {
	    margin-bottom: -44px; }
	  main section.rsvp h5.subTitle {
		  font-size: 18px;
		  line-height: 28px; }  
	  main section.rsvp .addGuestBtn {
		  display: inline-block;
		  vertical-align: top;
		  border: 1px solid  #d6d6d6;
		  margin-bottom: 42px;
		  cursor: default;
		  position: relative; }
		  main section.rsvp .addGuestBtn:hover, main section.rsvp .addGuestBtn:active {
			  opacity: 1; }
		  main section.rsvp .addGuestBtn .icon {
			  display: inline-block;
			  vertical-align: top;
			  width: 32px;
			  height: 32px;
			  text-align: center;
			  cursor: pointer;
			  position: relative; }
		  main section.rsvp .addGuestBtn span {
	      -moz-transition-property: opacity;
	      -o-transition-property: opacity;
	      -webkit-transition-property: opacity;
	      transition-property: opacity;
	      -moz-transition-duration: 0.4s;
	      -o-transition-duration: 0.4s;
	      -webkit-transition-duration: 0.4s;
	      transition-duration: 0.4s;
	      -moz-transition-timing-function: ease-in-out;
	      -o-transition-timing-function: ease-in-out;
	      -webkit-transition-timing-function: ease-in-out;
	      transition-timing-function: ease-in-out; }
		  main section.rsvp .addGuestBtn .icon.add {
			  border-right: 1px solid #d6d6d6; }
		  main section.rsvp .addGuestBtn .icon.sub {
			  display: none;
			  border: 1px solid #d6d6d6;
			  position: absolute;
			  top: -1px;
			  right: -48px; }
			  main section.rsvp .addGuestBtn .icon.sub.on {
				  display: inline-block; }
		  main section.rsvp .addGuestBtn .icon:before {
				content: "";
				display: block;
				width: 11px;
				height: 3px;
				margin: 14px auto 0;
				background-color: #6c6c6c; }
			main section.rsvp .addGuestBtn .icon.add:after {
				content: "";
				display: block;
				width: 3px;
				height: 11px;
				margin: -7px auto 0;
				background-color: #6c6c6c; }
		  main section.rsvp .addGuestBtn .text {
			  display: inline-block;
			  padding: 5px 14px;
			  font: 11px/22px "AvenirNextLTW01-Regular";
			  color: #6c6c6c; }
			  main section.rsvp .addGuestBtn .icon:hover, main section.rsvp .addGuestBtn .icon:active {
				  opacity: 0.65; }
	  main section.rsvp .button {
		  display: block; }
		main section.rsvp .selectWrap {
      margin-bottom: 16px;
			position: relative; }
			main section.rsvp .selectWrap:after {
				content: "";
				display: block;
				width: 7px;
				height: 7px;
				border-right: 2px solid #6c6c6c;
				border-bottom: 2px solid #6c6c6c;
				position: absolute;
				top: 14px;
				right: 0;
				pointer-events: none;
				transform: rotate(45deg);
				transform-origin: left top; }
    main section.rsvp select {
      width: 100%;
      height: 44px;
      padding-right: 25px;
      border-radius: 0;
      border-bottom: solid 1px #e2e2e2;
      font: 17px/44px "AvenirNextLTW01-Regular";
      font-weight: normal;
      color: #6c6c6c;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      outline: none;
      background-color: transparent;
      -moz-transition-property: margin;
      -o-transition-property: margin;
      -webkit-transition-property: margin;
      transition-property: margin;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
        main section.rsvp select::-ms-expand {
					display: none; }
        main section.rsvp select option {
          height: 34px;
          border-bottom: solid 1px #e2e2e2;
          background-color: #ffffff; }
        main section.rsvp select.otherSelect {
	        margin-bottom: 0; }
    main section.rsvp select.meal {
	    padding-left: calc(100% - 68px); }
	  main section.rsvp .otherField {
			opacity: 0;
		  height: 0;
		  overflow: hidden;
		  will-change: height;
		  border-bottom-color: transparent;
      -moz-transition-property: opacity, height, border;
      -o-transition-property: opacity, height, border;
      -webkit-transition-property: opacity, height, border;
      transition-property: opacity, height, border;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out; }
      main section.rsvp .otherField.select {
	      opacity: 1;
				height: 44px;
	      border-bottom-color: #e2e2e2; }
    main section.rsvp .radio {
      display: none; }
	    main section.rsvp .radio + label {
	      display: inline-block;
	      vertical-align: middle;
	      margin-left: 24px;
	      text-align: left;
	      font: 13px/24px "AvenirNextLTW01-Regular";
	      font-weight: normal;
	      cursor: pointer;
		    -moz-box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		    box-sizing: border-box; }
		    main section.rsvp .radio + label span, main section.rsvp .checkbox + label span {
		      display: inline-block;
		      vertical-align: middle; }
		    main section.rsvp .radio + label span {
			    width: 14px;
			    height: 14px;
			    border: solid 1px #c0c0c0;
			    margin-right: 5px;
			    background-repeat: no-repeat;
			    background-size: 100%;
		      -moz-transition-property: background-color;
		      -o-transition-property: background-color;
		      -webkit-transition-property: background-color;
		      transition-property: background-color;
		      -moz-transition-duration: 0.4s;
		      -o-transition-duration: 0.4s;
		      -webkit-transition-duration: 0.4s;
		      transition-duration: 0.4s;
		      -moz-transition-timing-function: ease-out;
		      -o-transition-timing-function: ease-out;
		      -webkit-transition-timing-function: ease-out;
		      transition-timing-function: ease-out; }
		      main section.rsvp .radio + label span:before, main section.rsvp .radio + label span:after {
			      content: "";
			      display: block;
			      width: 20px;
			      height: 1px;
			      position: absolute;
			      left: -3px; }
			    main section.rsvp .radio + label span:before {
				    top: 7px;
				    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
			    main section.rsvp .radio + label span:after {
				    top: 7px;
				    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
		    main section.rsvp .radio + label:hover span {
		      background-color: #eaeaea; }
		    main section.rsvp .radio:checked + label span:before, main section.rsvp .radio:checked + label span:after {
		      background-color: #6c6c6c; }
    main section.rsvp .checkbox {
      display: none; }
	    main section.rsvp .checkbox + label {
	      display: block;
	      height: 44px;
	      margin-bottom: 16px;
	      text-align: left;
	      font: 17px/44px "AvenirNextLTW01-Regular";
	      font-weight: normal;
	      border-bottom: solid 1px #e2e2e2;
	      cursor: pointer;
		    -moz-box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		    box-sizing: border-box;
		    position: relative; }
		    main section.rsvp .checkbox + label span {
			    width: 14px;
			    height: 14px;
			    border: solid 1px #c0c0c0;
			    position: absolute;
			    bottom: 16px;
			    right: 0;
			    background-repeat: no-repeat;
			    background-size: 100%;
		      -moz-transition-property: background-color;
		      -o-transition-property: background-color;
		      -webkit-transition-property: background-color;
		      transition-property: background-color;
		      -moz-transition-duration: 0.4s;
		      -o-transition-duration: 0.4s;
		      -webkit-transition-duration: 0.4s;
		      transition-duration: 0.4s;
		      -moz-transition-timing-function: ease-out;
		      -o-transition-timing-function: ease-out;
		      -webkit-transition-timing-function: ease-out;
		      transition-timing-function: ease-out; }
		      main section.rsvp .checkbox + label span:before, main section.rsvp .checkbox + label span:after {
			      content: "";
			      display: block;
			      width: 20px;
			      height: 1px;
			      position: absolute;
			      left: -3px; }
			    main section.rsvp .checkbox + label span:before {
				    top: 7px;
				    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
			    main section.rsvp .checkbox + label span:after {
				    top: 7px;
				    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
		    main section.rsvp .checkbox + label:hover span {
		      background-color: #eaeaea; }
		    main section.rsvp .checkbox:checked + label span:before, main section.rsvp .checkbox:checked + label span:after {
		      background-color: #6c6c6c; }
		main section.rsvp p {
			margin-top: 20px; }
footer {
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 400; }
  footer:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
  	  background: #ccb16f;
      /* set border color here, for gradient replace entire line */ }
  footer.coverpage {
    display: none !important; }

  
body.password {
	height: 100%;
	text-align:center;
}
#outerSpace.password {
	display: table;
	width: 100%;
	height: 100%;
	text-align:center;
}
.mainContent.password {
	display: table-cell;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	text-align:center;
}
.mainContent.password .group {
	padding: 40px;
}
.mainContent.password form {
	max-width: 400px; 
	margin: 0 auto;
	position: relative;}
.mainContent.password .borderWrap {
	margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding: 1px;
  	  background: #ccb16f;
   }
.mainContent.password input {
  text-align: center; }


@media (min-width: 1498px) {
	main section.gallery-thumbnails .section {
		margin-bottom: 40px;
	}
}


@media (max-width: 1280px) and (min-width: 769px) {
  }

@media (min-width: 1098px) {
	main section.gallery-collage {
		max-width: 1020px;
	}
	main section.gallery-collage .section:nth-child(3n+0) {
		margin-right: 0;
	}
}

@media (max-width: 1097px) {
	main section.gallery-collage {
		max-width: 678px;
	}
	main section.gallery-collage .section:nth-child(2n+0) {
		margin-right: 0;
	}
}

@media (max-width: 1012px) {
	main section.gallery-thumbnails .section {
		width: 47.85%;
		margin-right: 4.3%;
		margin-bottom: 4vw; }
	main section.gallery-thumbnails .section:nth-child(3n+0) {
		margin-right: 4.3%; }
	main section.gallery-thumbnails .section:nth-child(2n+0) {
		margin-right: 0; }
	main section.gallery-thumbnails .section:nth-last-of-type(3) {
		margin-bottom: 4vw !important; }
}

@media (max-width: 920px) {
  }

@media (max-width: 759px) {
	main section.gallery-collage .section {
		width: 49.5%;
    margin-right: 0.6vw;
    margin-bottom: 0.6vw;
	}
}

@media (max-width: 768px) {
	/*
	.button {
		margin-top: 5px;
		margin-bottom: 5px; }
*/
	.buttonCont {
		white-space: normal; }
	/* set mongram mobile alignment here */
	header {
		padding-left: 20px;
		padding-right: 20px;
			  text-align: left;
		 }
		
	    
		header h1.monogram {
					  padding-left: 0;
			 }
	header img {
    max-height: 60px; }
  header.coverpage + #mm {
    top: 20px; }
	
  #mm {
    top: 20px;
				    right: 20px;
		    /* set menu alignment based on monogram position */
	}
	
	#navContent.split {
		display: block; }
		#navContent.split .bgBox {
			display: none; }
		#navContent.split nav {
			width: 100%;
			height: 100%; }
	
	  
	main:not(.singlePage) section .section:not(.full):not(.split) .sectionCont:last-child, main:not(.singlePage) section.gallery .section:last-child, main:not(.singlePage) section.gallery .credit, main:not(.singlePage) section.gallery-collage .credit, main:not(.singlePage) section.gallery-thumbnails .credit, main:not(.singlePage) section.rsvp form {
		margin-bottom: 20px; }
	main.singlePage section:last-of-type .section:not(.full):not(.split) .sectionCont:last-child, main.singlePage section.gallery:last-of-type, main.singlePage section.gallery-collage:last-of-type, main.singlePage section.gallery-thumbnails:last-of-type, main.singlePage section.rsvp:last-of-type, main.singlePage section:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery-collage:last-of-type[style*="display: none"] + .spacer, main.singlePage section.gallery-thumbnails:last-of-type[style*="display: none"] + .spacer,  main.singlePage section.rsvp:last-of-type[style*="display: none"] + .spacer {
		margin-bottom: 20px; }
		
	main section .section.stagger_l .sectionCont .imgCont, main section .section.stagger_r .sectionCont .imgCont {
		width: 100%;
		min-width: 0; }
		
	main section .section.full .sectionCont.small .imgCont:not(.videoCont):not(.doNotCrop), main section .section.full .sectionCont.small .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
		min-height: 25vh; }
	main section .section.full .sectionCont.medium .imgCont:not(.videoCont):not(.doNotCrop), main section .section.full .sectionCont.medium .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
		min-height: 84.78vh; }
	main section .section.full .sectionCont.large .imgCont:not(.videoCont):not(.doNotCrop), main section .section.full .sectionCont.large .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
		min-height: 100vh; }
	main section .section.large .sectionCont.small .imgCont:not(.videoCont):not(.doNotCrop), main section .section.large .sectionCont.small .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
		min-height: 51.27vh; }
	main section .section.large .sectionCont.medium .imgCont:not(.videoCont):not(.doNotCrop), main section .section.large .sectionCont.medium .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
		min-height: 69.47vh; }
		main section .section.large .sectionCont.large .imgCont:not(.videoCont):not(.doNotCrop), main section .section.large .sectionCont.large .imgCont:not(.videoCont):not(.doNotCrop) .textCont {
			min-height: 89.4vh; }
	
	main section .section.split .sectionCont.small .imgCont:not(.videoCont), main section .section.split .sectionCont.small .imgCont:not(.videoCont) .textCont {
    min-height: 38.86vh !important; }
  main section .section.split .sectionCont.medium .imgCont:not(.videoCont), main section .section.split .sectionCont.medium .imgCont:not(.videoCont) .textCont {
    min-height: 56.25vh !important; }
  main section .section.split .sectionCont.large .imgCont:not(.videoCont), main section .section.split .sectionCont.large .imgCont:not(.videoCont) .textCont {
    min-height: 73.4vh !important; }	
  main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.small .imgCont:not(.videoCont) .textCont, main section .section.boxes2 .sectionCont.small .imgCont:not(.videoCont) .textCont {
    min-height: 35.73vh !important; }
  main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.medium .imgCont:not(.videoCont) .textCont, main section .section.boxes2 .sectionCont.medium .imgCont:not(.videoCont) .textCont {
    min-height: 47.74vh !important; }
  main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont), main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont), main section .section.boxes3 .sectionCont.large .imgCont:not(.videoCont) .textCont, main section .section.boxes2 .sectionCont.large .imgCont:not(.videoCont) .textCont {
    min-height: 69.47vh !important; }
  main section .section.stagger_r .sectionCont.small .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.small .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.small .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.small .imgCont:not(.videoCont) .textCont {
    min-height: 35.73vh; }
  main section .section.stagger_r .sectionCont.medium .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.medium .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.medium .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.medium .imgCont:not(.videoCont) .textCont {
    min-height: 47.74vh; }
  main section .section.stagger_r .sectionCont.large .imgCont:not(.videoCont), main section .section.stagger_l .sectionCont.large .imgCont:not(.videoCont), main section .section.stagger_r .sectionCont.large .imgCont:not(.videoCont) .textCont, main section .section.stagger_l .sectionCont.large .imgCont:not(.videoCont) .textCont {
    min-height: 69.47vh; }
	main section.rsvp .imgCont, main section.rsvp .mediaCont  {
		min-height: 68.53vh; }
	
		main section .sectionCont > .textCont {
			padding-left: 20px;
			padding-right: 20px; }
		main section .textCont > .subsection {
			margin-bottom: 30px; }

        main section .textCont > .subsection .palette{
            width: 50%;
            display: block;
        }

        main section .textCont > .subsection .map{
            width: 100%;
            display: block;
        }

        main section .textCont > .subsection .indian-attire{
            width: 50%;
            display: block;
        }

        main section .section.stagger_l .sectionCont > .textCont .palette, main section .section.stagger_r .sectionCont > .textCont .palette {
          margin-left: 25%; } 

        main section .section.stagger_l .sectionCont > .textCont .indian-attire, main section .section.stagger_r .sectionCont > .textCont .indian-attire  {
          margin-left: 25%; }

        main section main section .section.stagger .sectionCont > .textCont .map  {
          margin-left: 25%; }
/*
			main section .textCont > .subsection {
				margin-bottom: 16px; }
*/
	
    main section.coverpage {
	    padding: 0 20px; }
    main section.coverpage.singleCoverpage.addPadd {
	    margin-top: 20px !important; }
		main section.coverpage h1.logo {
			padding: 20px; }
			main section.coverpage.bottom h1.logo {
				padding: 0 20px; }
			main section.coverpage.header h1.logo {
				left: 0;
				right: 0; }
			main section.coverpage.header h1.logo img, main section.coverpage.header h1.logo span {
				margin-left: 20px;
				margin-right: 20px; }
			main section .section .sectionCont .imgCont.textBelow {
				margin-bottom: 20px; }
				
		main section .section.large, main section .section.boxes2, main section .section.boxes3, main section .section.stagger_l, main section .section.stagger_r {
			padding: 20px 20px 0; }
			main section .section.large .sectionCont + .sectionCont, main section .section.large .sectionCont + style + .sectionCont {
				margin-top: 20px;
				padding-top: 20px; }
		main section .section.boxes2, main section .section.boxes3 {
      display: block; }
			main section .section.boxes2 .sectionCont, main section .section.boxes3 .sectionCont {
				width: 100%;
				margin: 0 0 20px !important; }
/*
				main section .section.boxes2 .sectionCont:last-child, main section .section.boxes3 .sectionCont:last-child {
					margin-bottom: 0 !important;
				}
*/
				main section .section.boxes3 {
					margin-left: auto;
					margin-right: auto;	}
/*
			main section.coverpage.header h1.logo, main section.coverpage.valign-top h1.logo {
				padding-top: 20px;
				padding-bottom: 20px;
			  font-size: 6vw; }
*/
/*
			  main section.coverpage.halign-center.header h1.logo, main section.coverpage.halign-center.valign-top h1.logo {
			  				  text-align: left;
				  margin-right: auto;
				  padding-left: 20px !important;
				 }
*/
  main section .section.stagger_l .sectionCont, main section .section.stagger_r .sectionCont {
    display: block; }
    main section .section.stagger_l .sectionCont .imgCont, main section .section.stagger_r .sectionCont .imgCont {
      display: block;
      max-width: none; }
    main section .section.stagger_l .sectionCont > .textCont, main section .section.stagger_r .sectionCont > .textCont {
      display: block;
      max-width: 90%;
      margin: 20px auto;
      padding: 0 !important;
      text-align: center !important; }
      /* main section .section.stagger_l .sectionCont .imgCont .textCont, main section .section.stagger_r .sectionCont .imgCont .textCont {
	      max-height: none !important; } */
      main section .section.stagger_l .sectionCont > .textCont .button, main section .section.stagger_r .sectionCont > .textCont .button {
	      margin-left: auto !important;
	      margin-right: auto !important; }
    main section .section.stagger_l .sectionCont + .sectionCont, main section .section.stagger_r .sectionCont + .sectionCont, main section .section.stagger_l .sectionCont + style + .sectionCont, main section .section.stagger_r .sectionCont + style + .sectionCont {
      margin-top: 10px;
      padding-top: 10px; }
    main section .section.split {
	    display: block; }
		main section .section.split, main section .section.full {
			padding-top: 20px; }
    main section .section.split .sectionCont {
	    display: block;
	    width: auto !important; }
    main section .section.split .sectionCont .imgCont.textBelow {
			margin-bottom: 20px; }
    main section .section.split .sectionCont .imgCont {
			width: 100%; }
		main section .section.split .sectionCont > .textCont {
			width: auto;
			padding-left: 20px;
			padding-right: 20px; }
			main section .section.split .sectionCont .imgCont .textCont {
				padding: 30px 20px; }
				main section .section.split .sectionCont > .textCont, main section .section.full .sectionCont > .textCont {
					margin-bottom: 20px; }
/*
				main section .section.full + .section.large, main section .section.full + .section.boxes2, main section .section.full + .section.boxes3, main section .section.full + .section.stagger_l, main section .section.full + .section.stagger_r, main section .section.split + .section.large, main section .section.split + .section.boxes2, main section .section.split + .section.boxes3, main section .section.split + .section.stagger_l, main section .section.split + .section.stagger_r, main section .section.addMargin {
				margin-top: 10px; }
*/
		main section .section.full .sectionCont > .textCont.textOnly, main section .section.split .sectionCont > .textCont.textOnly {
			padding-top: 30px; }
/*
			main section .section.split .sectionCont.small .imgCont, main section .section.split .sectionCont.small > .textCont {
			  height: 120px; }
		  main section .section.split .sectionCont.medium .imgCont, main section .section.split .sectionCont.medium > .textCont {
		    height: 320px; }
		  main section .section.split .sectionCont.large .imgCont, main section .section.split .sectionCont.large > .textCont {
		    height: 426px; }
*/
	main section.gallery, main section.gallery-thumbnails, main section.gallery-collage {
		padding-top: 20px;
	}
	main section .section.gallery {
		padding: 20px 0 0; }
	main section .section.gallery-thumbnails {
		padding: 20px 0 0; }
		main section .section.gallery-thumbnails .credit {
			padding: 20px 0 0; }
	main section .section.gallery-collage {
		padding: 20px 0 0; }
  main section.rsvp.rsvpOne {
	  padding-top: 20px; }
  main section.rsvp.topImage.addPadd {
	  padding-top: 30px;
	 	margin-top: 20px !important; }
	  main section.rsvp.topImage.addPadd > .imgCont, main section.rsvp.topImage.addPadd > .mediaCont {
		  top: 20px; }
		main section.rsvp .button {
			margin-top: 20px;	}
	
	.mainContent.password .group {
		padding: 20px; }
}

@media (max-width: 600px) {
	/* main section .section.full .sectionCont.small .imgCont:not(.videoCont), main section .section.full .sectionCont.small .imgCont .mediaCont, main section.rsvp > .imgCont {
		height: 225px !important;
		overflow: hidden;
	} */
	/* main section .section.full .sectionCont.small .imgCont:not(.noImageSized) img, main section.rsvp > .imgCont img {
		width: 600px;
		margin-left: -300px;
		position: absolute;
		top: 0;
		left: 50%;
		transition-property: opacity;
	} */
    main section.rsvp .content {
	    padding: 20px 20px 20px !important; }
	    main section.rsvp form.rsvpImage .content {
	    margin: 20px 0 0; 
		}
		main section.rsvp h3 {
			margin-top: 36px;
		}
}

/* @media (max-width: 574px) {
	main section .section.large .sectionCont.small .imgCont:not(.videoCont), main section .section.large .sectionCont.small .imgCont .mediaCont {
		height: 200px !important;
		overflow: hidden;
	}
	main section .section.large .sectionCont.small .imgCont:not(.noImageSized) img {
		width: 534px;
		margin-left: -267px;
		position: absolute;
		top: 0;
		left: 50%;
		transition-property: opacity;
	}
} */

/* @media (max-width: 640px) {
	main section .section.full .sectionCont.small .imgCont:not(.videoCont), main section .section.full .sectionCont.small .imgCont:not(.videoCont) .textCont {
		min-height: 300px; }
	main section .section.full .sectionCont.medium .imgCont:not(.videoCont), main section .section.full .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		min-height: 360px; }
	main section .section.large .sectionCont.small .imgCont:not(.videoCont), main section .section.large .sectionCont.small .imgCont:not(.videoCont) .textCont {
		min-height: 275px; }
	main section .section.large .sectionCont.medium .imgCont:not(.videoCont), main section .section.large .sectionCont.medium .imgCont:not(.videoCont) .textCont {
		min-height: 335px; }
} */

@media (max-width: 480px) {
		
			input.large {
		  	font-size: 25.2px;
		}
}

@media (max-width: 360px) {
	
		input.large {
		  	font-size: 19.6px;
		}
}
